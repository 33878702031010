import React from 'react'
import s from './intro-welcome.module.scss'
import Icon from 'components/utilities/icons/Icon'

const IntroWelcome = () => {
  return (
    <div className={s.containerMain}>
      <div className={s.square}></div>
      <div className={s.topDots}></div>
      <div className={s.bottomDots}></div>
      <div className={s.bookMark}>
        <Icon.Bookmark />
      </div>
      <div className={s.search}>
        <Icon.Search />
      </div>
      <div className={s.question}>
        <Icon.QuestionMark />
      </div>
      <div className={s.menu}>
        <Icon.Menu />
      </div>
    </div>
  )
}

export default IntroWelcome
