import flattenCourseMap from 'components/wrappers/course-map-data-wrapper/flatten-course-map'
import { format } from 'date-fns'
import { nanoid } from 'nanoid'

const aosName = (aos) => {
  return `${
    aos.category?.slice(0, 2).toUpperCase() ||
    aos.aosType?.slice(0, 2).toUpperCase()
  } \u2013 ${aos.unitSetCode || aos.aos} ${aos.title || aos.aosName}`
}

export const compileMap = (planId, currentStudent, index, createdBy) => {
  const course = currentStudent.courseEnrolments?.filter(
    (item) => item.location
  )[index]

  if (!course) return null

  const newMap = {
    id: planId,
    planName: `${currentStudent.identifiers.callistaPersonID} \u2013 ${
      course.courseCode
    } \u2013 ${format(new Date(), 'dd MMM yyyy')}`,
    student: currentStudent.identifiers.callistaPersonID,
    code: course.courseCode,
    enrolmentDate:
      course.enrolmentDate || new Date().toISOString().split('T')[0], // TODO
    location: {
      country: course.location?.country,
      description: course.location?.description,
      id: course.location?.id,
      attendanceMode: course.attendanceMode.code,
      attendanceType: course.attendanceType.code,
    },
    progression: course.progression,
    status: course.status,
    shortTitle: course.course.shortTitle,
    title: course.course.title,
    version: course.course.version,
    logs: {
      created: { time: Date.now(), user: createdBy },
    },
    aos:
      (course.studyUnitSetEnrolments?._items || [])
        .filter((aos) => {
          return (
            !['RNA', 'COMP-MOD'].includes(aos.studyUnitSet.category) &&
            !aos.endDate &&
            aos.studentConfirmedIndicator === 'Y'
          )
        })
        .map((item) => {
          return {
            code: item.studyUnitSet.unitSetCode,
            name: aosName(item.studyUnitSet),
          }
        }) || [],
  }

  // advanced standings

  newMap.advancedStandings = []
  if (course.advancedStandings)
    course.advancedStandings.forEach((item) => {
      if (['GRANTED', 'APPROVED'].some((x) => x === item.grantingStatus)) {
        const block = {
          id: nanoid(),
          type: 'BLOCK',
          blockType: 'CREDIT',
          origin: 'CREDIT',
          category: item.category || null,
          creditPoints: item.creditPoints || null,
          creditType: item.creditType || null,
          disciplineDescription: item.disciplineDescription || null,
          grantingStatus: item.grantingStatus || null,
          recognitionType: item.recognitionType || null,
          name: item.unitCode || null,
          percentage: item.percentage || null,
          description: item.unitTitle || null,
          unitLevel: item.unitLevel || null,
        }

        newMap.advancedStandings.push(block)
      }
    })

  // years

  newMap.startingYear =
    course.enrolmentYear || new Date().toISOString().split('-')[0] // TODO
  newMap.years = {}

  Object.values(course.teachingPeriods).map((item) => {
    const academicYear = item.teachingPeriod.academicYear

    // if year doesn't exit yet, create
    if (!newMap.years[academicYear])
      newMap.years[academicYear] = {
        id: nanoid(),
        name: academicYear,
        periods: [],
      }

    const ignore = [
      'WD-EARLY',
      'WD-P-COVID',
      'WD-REMT-V',
      'WD-EARL-F',
      'WD-EARL-P',
      'WD-N-COVID',
      'WD-REMT-E',
    ]
    // fill period with blocks
    let tp = {
      id: nanoid(),
      name: item.teachingPeriod.calendarType.description || null,
      startDate: new Date(item.teachingPeriod.startDate) || null,
      blocks: Object.values(item.unitEnrolments)
        .filter(
          (unit) =>
            !unit.administrativeStatus ||
            !ignore.includes(unit.administrativeStatus)
        )
        .map((unit) => {
          const block = {
            id: nanoid(),
            type: 'BLOCK',
            blockType: 'UNIT',
            origin: 'MAP',
            creditPoints: unit.studyUnit.maxCreditPoints || null,
            name: unit.studyUnit.unitCode || null,
            status: unit.status || null,
            mark: unit.mark || unit.duplicateOf?.[0].mark || null,
            grade: unit.grade || unit.duplicateOf?.[0].grade || null,
            description: unit.studyUnit.title || null,
          }

          return block
        }),
    }
    if (tp.blocks.length > 0) {
      newMap.years[academicYear].periods.push(tp)
    }

    return item
  })

  const formatDate = (stringDate) => {
    let d = new Date(stringDate)
    return d.toLocaleDateString('default', {
      year: 'numeric', month: 'long', day: 'numeric'
    })
  }

  course.intermissions?.['_items']
    .filter(i => i.startDate !== i.endDate)
    .forEach((i) => {
      const year = i.startDate.split('-')[0]

      if (!newMap.years[year]) {
        newMap.years[year] = {
          id: nanoid(),
          name: year,
          periods: [],
        }
      }

      newMap.years[year].periods.push({
        id: nanoid(),
        name: 'Intermission',
        startDate: i.startDate,
        blocks: [{
          origin: 'MAP',
          blockType: 'INTERMISSION',
          id: nanoid(),
          description: `${formatDate(i.startDate)} \u2013 ${formatDate(i.endDate)}`,
          size: 4 
        }],
        disabled: true
      })
    })

  Object.keys(newMap.years).forEach((year) => {
    newMap.years[year].periods = newMap.years[year].periods.sort((a, b) =>
      a.startDate > b.startDate ? 1 : a.startDate < b.startDate ? -1 : 0
    )
  })

  newMap.original = {
    id: JSON.parse(JSON.stringify(newMap.id)),
    aos: JSON.parse(JSON.stringify(newMap.aos)),
    years: JSON.parse(JSON.stringify(newMap.years)),
  }

  return newMap
}

export const copyWithUpdate = (newPlan, previous) => {
  // create new map
  const created = { ...newPlan }

  const flatMap = flattenCourseMap(created).map(u => u.name)

  // for each year in new map
  Object.keys(created.years).forEach(year => {
    // for each period in oldmap.years[year]
    previous.years[year]?.periods.forEach(period => {
      // remove anything that appears in newFlatMap
      let copying = {
        ...period,
        blocks: period.blocks.filter(u => 
          u.blockType === 'COMMENT'
          || (!flatMap.includes(u.name) 
            && u.status === 'SUGGESTED' )
        )
      }

      // append to newMap.years[year]
      if (copying.blocks.length > 0) {
        created.years[year].periods.push(copying)
      }
    })
  })
    
  return created
}

export default compileMap
