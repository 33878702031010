import React, { useState } from 'react'
import c from './intro.module.scss'
import IntroWelcome from './intro-graphics/intro-welcome/IntroWelcome'
import IntroSearchAndBrowse from './intro-graphics/intro-search-and-browse/IntroSearchAndBrowse'
import IntroBookmark from './intro-graphics/intro-bookmark/IntroBookmark'
import IntroCreateCoursePlan from './intro-graphics/intro-create-course-plan/IntroCreateCoursePlan'
import IntroUnitSelection from './intro-graphics/intro-unit-selection/IntroUnitSelection'
import IntroHelpAndSupport from './intro-graphics/intro-help-and-support/IntroHelpAndSupport'

const displayItems = [
  {
    name: 'Welcome',
    description:
      'Welcome to the Monash Course Mapper, an all-in-one tool to assist you in aiding students plan their study at Monash.',
    graphic: <IntroWelcome />,
  },
  {
    name: 'Search & Browse',
    description:
      'The Monash Course Mapper allows you to search and browse for course planning related information and requirements.',
    graphic: <IntroSearchAndBrowse />,
  },
  {
    name: 'Bookmark & Compare',
    description:
      'A bookmarking system enables you to pin items such as courses, units and areas of study for quick access.',
    graphic: <IntroBookmark />,
  },
  {
    name: 'Create Course Plan',
    description: 'Start a student’s study journey by creating a course plan.',
    graphic: <IntroCreateCoursePlan />,
  },
  {
    name: 'Unit Selection',
    description:
      'All units offered by Monash University are listed in this tool. You can select and arrange units to fit into any course plan.',
    graphic: <IntroUnitSelection />,
  },
  {
    name: 'Help & Support',
    description:
      'The Monash Course Mapper also provides help and support resources such as an in-depth tutorial, feedback form, and a glossary.',
    graphic: <IntroHelpAndSupport />,
  },
]

const Intro = ({ onBeginClick }) => {
  const [activeItemIndex, setactiveItemIndex] = useState(0)
  const activeItem = displayItems[activeItemIndex]

  return (
    <div className={c.welcomeIntro}>
      <div className={c.content}>
        <div className={c.graphic}>{activeItem.graphic}</div>
        <h1>{activeItem.name}</h1>
        <p>{activeItem.description}</p>
      </div>
      <div className={c.nav}>
        <div className={c.dotsWrapper}>
          {displayItems.map((item, i) => {
            return (
              <div
                key={i}
                onClick={() => {
                  setactiveItemIndex(i)
                }}>
                <div
                  className={`${c.dot} ${
                    i === activeItemIndex ? c.active : ''
                  }`}></div>
              </div>
            )
          })}
        </div>
        <button
          className={c.buttonNext}
          onClick={
            displayItems.length - 1 !== activeItemIndex
              ? () => {
                  setactiveItemIndex(activeItemIndex + 1)
                }
              : onBeginClick
          }>
          {activeItemIndex === displayItems.length - 1 ? 'Get Started' : 'Next'}
        </button>
      </div>
    </div>
  )
}

export default Intro
