import { Data } from 'components/wrappers/course-map-data-wrapper/CourseMapDataWrapper'
import { Interface } from 'components/wrappers/interface-data-wrapper/InterfaceDataWrapper'
import React, { useContext, useEffect, useState } from 'react'
import AdvancedStandings from './advanced-standings/AdvancedStandings'
import c from './mapper.module.scss'
import Years from './years/Years'
import { isPass } from 'utils/data/grades'

const Mapper = () => {
  const { courseMap, flatCourseMap } = useContext(Data)
  const { displayOptions, setShowDisplayOptions } = useContext(Interface)
  const [count, setCount] = useState(0)

  useEffect(() => {
    setCount(
      flatCourseMap.filter(
        (item) =>
          ['DISCONTIN'].includes(item.status) ||
          (item.grade && !isPass(item.grade))
      ).length
    )
  }, [flatCourseMap])

  // render

  return (
    <div className={c.mapper}>
      {!displayOptions.showFailedUnits && count ? (
        <div className={c.unitsHiddenContainer}>
          <div className={c.unitsHidden}>
            {count} withdrawn or failed{' '}
            {count > 1 || count === 0 ? 'units are' : 'unit is'} hidden.{' '}
            <span onClick={() => setShowDisplayOptions(true)}>
              Change display options
            </span>
            .
          </div>
        </div>
      ) : null}
      {courseMap?.advancedStandings.length ? <AdvancedStandings /> : null}
      <Years />
    </div>
  )
}

export default Mapper
