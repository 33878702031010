import React, { useEffect, useState } from 'react'
import c from './loading-indicator.module.scss'
import LoadingIndicator from './LoadingIndicator'

const PartialLoadingScreen = ({ children, loaded, style }) => {
  const [mount, setMount] = useState(false)

  useEffect(() => {
    if (loaded) setTimeout(() => setMount(true), 300)
    setMount(false)
  }, [loaded])

  // render

  return (
    <>
      {mount ? (
        children
      ) : (
        <div
          className={c.loadingScreen}
          style={{ ...style, opacity: loaded ? 0 : 1 }}
        >
          <LoadingIndicator size={90} />
        </div>
      )}
    </>
  )
}

export default PartialLoadingScreen
