import React from 'react'
import { convertLinks } from 'utils/transforms/string-transforms'
import c from './view-plan.module.scss'

const VNote = ({ note }) => {
  // render

  return (
    <div className={c.Note}>
      <div className={c.poke} />
      <div className={c.noteContent}>{convertLinks(note)}</div>
    </div>
  )
}

export default VNote
