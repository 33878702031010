import React from 'react'
import c from './toggle.module.scss'

const Toggle = ({ onClick, on = false }) => {
  // render

  return (
    <button
      className={[c.Toggle, on ? c.on : c.off].join(' ')}
      onClick={onClick}>
      <div className={c.circle} />
    </button>
  )
}

export default Toggle
