import { Interface } from 'components/wrappers/interface-data-wrapper/InterfaceDataWrapper'
import React, { useContext } from 'react'
import c from './noteTag.module.scss'

const NoteTag = ({ note, showNotes }) => {
  const {
    displayOptions: { condenseMode },
  } = useContext(Interface)

  if (!note) return null
  if (note && showNotes) return null
  return (
    <div className={`${c.noteTag} ${condenseMode ? c.condense : null}`}>
      <div className={c.poke} />
    </div>
  )
}

export default NoteTag
