import Logo from 'components/utilities/icons/Logo'
import { CurrentStudent } from 'components/wrappers/student-data-wrapper/StudentDataWrapper'
import React, { useContext } from 'react'
import ProfileMenu from './profile-menu/ProfileMenu'
import SearchBox from './search-box/SearchBox'
import c from './topnav.module.scss'

const Topnav = ({ noProfile = false }) => {
  const { currentStudent } = useContext(CurrentStudent)
  // render

  return (
    <div className={c.topnav} id='top-nav'>
      <div className={c.left}>
        <Logo.MonashStacked />
        <div className={c.divider} />
        <h2 className={c.title}>Course Mapper</h2>
      </div>
      <div className={c.right}>
        {currentStudent && <SearchBox />}
        {!noProfile && <ProfileMenu />}
      </div>
    </div>
  )
}

export default Topnav
