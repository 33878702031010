import Icon from 'components/utilities/icons/Icon'
import { Data } from 'components/wrappers/course-map-data-wrapper/CourseMapDataWrapper'
import React, { useContext, useEffect, useState } from 'react'
import c from './area-of-study.module.scss'
import SearchAoS from './search-aos/SearchAoS'
import { isPass } from 'utils/data/grades'
import AosTag from './AosTag/AosTag'
import { Interface } from 'components/wrappers/interface-data-wrapper/InterfaceDataWrapper'
import deepCopy from 'utils/edit-map/deep-copy-map'

const AreaOfStudy = () => {
  // AoS data
  const {
    courseMap,
    setCourseMap,
    setInEditCourseMap,
    autosave,
    flatCourseMap,
    selectedPlan,
  } = useContext(Data)
  const { editMode, setEditMode } = useContext(Interface)

  const countAos = (code) => {
    let count = 0
    flatCourseMap.forEach((block) => {
      if (
        block.aos?.some((aos) => aos.code === code) &&
        (block.grade
          ? isPass(block.grade)
          : !['DISCONTIN'].includes(block.status))
      ) {
        count++
      }
    })
    return count
  }

  // selected AoS
  const renderAoS = () => {
    return courseMap.aos?.map((item, i) => {
      const warning =
        parseInt(item.start, 10) > parseInt(courseMap.startingYear, 10)
      const count = countAos(item.code)

      const remove = () => {
        setCourseMap((f) => {
          const newMap = { ...f }
          newMap.aos.splice(i, 1)
          newMap.advancedStandings.forEach((block, i) => {
            block.aos = block.aos?.filter((aos) => aos.code !== item.code) || []
          })

          Object.values(newMap.years).forEach((year) =>
            year.periods.forEach((period) =>
              period.blocks.forEach((block, i) => {
                block.aos =
                  block.aos?.filter((aos) => aos.code !== item.code) || []
              })
            )
          )

          autosave(newMap)
          return newMap
        })
      }

      const bulkEdit = (aos) => {
        const newMap = deepCopy(courseMap)
        setEditMode(aos)
        setInEditCourseMap(newMap)
      }

      return (
        <AosTag
          key={i}
          aos={item}
          count={count}
          remove={remove}
          bulkEdit={bulkEdit}
          warning={warning}>
          {item.name}
        </AosTag>
      )
    })
  }

  // search AOS
  const [searchingAOS, setSearchingAOS] = useState(false)

  // reset when selected plan changes
  useEffect(
    () => setSearchingAOS(false),
    [setSearchingAOS, editMode, selectedPlan]
  )

  // render

  return courseMap ? (
    <div className={c.areaOfStudy}>
      <div className={c.header}>
        <h3>Areas of study</h3>
      </div>
      <div className={c.listOfAoS}>
        <ul className={c.list}>
          {renderAoS()}

          {searchingAOS || editMode ? null : (
            <button
              className={c.addButton}
              onClick={() => setSearchingAOS(!searchingAOS)}>
              <Icon.Plus />
              <span>Add</span>
            </button>
          )}
        </ul>
        {searchingAOS && <SearchAoS {...{ searchingAOS, setSearchingAOS }} />}
      </div>
    </div>
  ) : null
}

export default AreaOfStudy
