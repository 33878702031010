import Icon from 'components/utilities/icons/Icon'
import LoadingIndicator from 'components/utilities/loading-indicator/LoadingIndicator'
import { Data } from 'components/wrappers/course-map-data-wrapper/CourseMapDataWrapper'
import React, { useContext, useEffect, useState } from 'react'
import { handbookSearch } from 'utils/services'
import c from './unit-search.module.scss'
import { isPass } from 'utils/data/grades'

const UnitSearch = ({ saveUnit, setShowSearch }) => {
  const { flatCourseMap } = useContext(Data)
  const [searchTerm, setSearchTerm] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setSearchTerm('')
  }, [])

  useEffect(() => {
    const debounce = setTimeout(() => {
      if (searchTerm) {
        setLoading(true)
        handbookSearch({ target: searchTerm, type: 'unit', size: 10 }).then((res) => {
          setSearchResults(
            res.hits?.hits.filter(
              (a, i, arr) =>
                arr.findIndex((b) => a._source.code === b._source.code) === i
            ) || [null]
          )
          setLoading(false)
        })
      } else {
        setSearchResults([])
      }
    }, 300)

    return () => clearTimeout(debounce)
  }, [searchTerm])

  const cancelSearch = () => {
    setShowSearch(false)
  }

  // render

  return (
    <div className={c.unitSearch}>
      <div className={c.container}>
        <header>
          <label>Add unit</label>
          <button onClick={cancelSearch}>
            Cancel
            <Icon.Remove />
          </button>
        </header>

        <input
          autoFocus
          placeholder='Type to search'
          type='text'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <div className={c.searchResults}>
          {(searchResults.length || loading) && (
            <ul>
              {loading && (
                <li className={c.loading}>
                  <LoadingIndicator color='#204f88' />
                </li>
              )}
              {searchResults.map((item, i) => {
                if (item) {
                  const exists = flatCourseMap.some(
                    (unit) => {
                      if (item._source.code === unit.name) {
                        return (unit.grade && isPass(unit.grade)) || !unit.grade
                      } else {
                        return false
                      }
                    }
                  )
                  return (
                    <li
                      key={i}
                      onClick={() => (exists ? null : saveUnit(item._source))}
                      className={exists ? c.disabled : null}>
                      <div className={c.code}>
                        <b>{item._source.code}</b>
                        <span>{exists && 'In plan'}</span>
                      </div>
                      <div className={c.name}>{item._source.title}</div>
                    </li>
                  )
                } else {
                  return <li key={i}>No results</li>
                }
              })}
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}

export default UnitSearch
