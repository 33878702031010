import React, { useState, useEffect } from 'react'
import c from './search-results.module.scss'
import { handbookFilters } from 'utils/services'

const Filters = ({type, selected, setFilters}) => {

  const [filterList, setFilterList] = useState([])

  useEffect(() => {
      handbookFilters().then((r) => {
        setFilterList(r.hits.hits)
      })
    }, [])

  const relevantFilters = () => {
    if (filterList.length === 0) { return []}
    if (type === 'all') {
      return [filterList.filter((f) => f['_source'].field === 'implementationYear')[0]]
    } else {
      type = type === 'area_of_study' ? 'aos' : type
      return filterList.filter((f) => f['_source'].type === type)
    }
  }

  const changeFilter = (field, value) => {
    let changed = [...selected]
    let i = changed.findIndex((f) => f.field === field)
    if (i === -1) {
      //no filter for this yet
      changed.push({field: field, values: [value]})
    } else {
      if (changed[i].values.includes(value)) {
        //unchecking, remove from values
        changed[i].values = changed[i].values
          .filter((v) => v !== value)
      } else {
        //add to filters
        changed[i].values.push(value)
      }
    }
    if (changed[i]?.values.length === 0) {
      changed = changed.filter((f) => f.field !== field)
    }
    console.log(changed)
    setFilters(changed)
  }

  const isChecked = (field, value) => {
    let found = selected
      .filter((f) => f.field === field && f.values.includes(value))
    return found.length > 0
  }

  return (
      filterList && 
      <div className={c.filterList}>
          <h1>Filters</h1>
          {relevantFilters()
            .map((f, i) => {
              return (
                <div className={c.field}>
                  <span className={c.label}>{f['_id'].split('-')[1]}</span>
                  <div className={c.values}>
                    {f['_source'].values.map((v) => {
                      return (
                        <span>
                          <input 
                            type="checkbox" 
                            id={v.label} 
                            value={v.label}
                            checked={isChecked(f['_source'].field, v.label)}
                            onChange={() => changeFilter(f['_source'].field, v.label)}
                          />
                          <label for={v.label}>
                            {v.label}
                          </label>
                        </span>)
                    })}
                  </div>
                </div>
              )
            })
          }
      </div>
  )
}

export default Filters