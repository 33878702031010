import React, { useState } from 'react'
import c from './starRating.module.scss'
const StarRating = ({ rating = 0, stars = 5, getRating }) => {
  const [ratingNumber, setRatingNumber] = useState(rating)

  const starsSlots = []
  for (let i = 1; i <= stars; i++) {
    let classNameTemp = c.star

    if (ratingNumber >= i && ratingNumber != null) {
      classNameTemp = c.star + ' ' + c.isSelected
    }

    starsSlots.push(
      <span
        key={i}
        className={classNameTemp}
        onClick={() => {
          setRatingNumber(i)
          getRating(i)
        }}>
        ★
      </span>
    )
  }
  return (
    <div className={c.starRow}>
      <span>Poor</span>
      {starsSlots} <span>Excellent</span>
    </div>
  )
}
export default StarRating
