import Icon from 'components/utilities/icons/Icon'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import c from './collapsible.module.scss'

const Collapsible = ({ children, header, expanded = true }) => {
  const [collapsed, setCollapsed] = useState(!expanded)
  const [height, setHeight] = useState(0)

  // observer

  const observer = useMemo(
    () =>
      new ResizeObserver((entries) =>
        setHeight(entries[0].target.scrollHeight)
      ),
    []
  )

  const body = useCallback(
    (node) => {
      if (node) observer.observe(node)
    },
    [observer]
  )

  useEffect(() => {
    return () => observer.disconnect()
  }, [observer])

  // render

  return (
    <div className={c.collapsible}>
      <div className={c.header} onClick={() => setCollapsed(!collapsed)}>
        <span
          className={c.toggle}
          style={{ transform: `rotate(${collapsed ? -90 : 0}deg)` }}>
          <Icon.ChevronDown />
        </span>
        {header}
      </div>
      <div
        className={c.body}
        style={{ height: `${collapsed ? 0 : height}px` }}>
        <div
          className={c.content}
          ref={body}
          style={
            collapsed ? { opacity: 0 } : { opacity: 1, transitionDelay: '.3s' }
          }>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Collapsible
