import React, { useContext, useState } from 'react'
import c from './rules.module.scss'
import { CurrentStudent } from '../../../../wrappers/student-data-wrapper/StudentDataWrapper'
import Accordion from '../../modules/accordion/Accordion'
import Icon from 'components/utilities/icons/Icon'
import { BrowserData } from 'components/browser/Browser'

const Rules = ({ callista, type }) => {
  const { currentStudent } = useContext(CurrentStudent)
  const { historyValues, updateHistory } = useContext(BrowserData)

  const versions = Object.keys(callista)

  const studentsVersion = () => {
    let v = null
    if (currentStudent.courseEnrolments) {
      let ruleType = type === 'Area of Study' ? 'aos' : type.toLowerCase()
      switch (ruleType || '') {
        case 'course':
          currentStudent.courseEnrolments.some((course) => {
            if (course.courseCode === callista[Object.keys(callista)[0]].code) {
              v = course.courseVersionNumber
              return true
            } else {
              return false
            }
          })
          break
        case 'aos':
          currentStudent.courseEnrolments.some((course) => {
            return course?.studyUnitSetEnrolments['_items'].some((aos) => {
              if (
                aos.studyUnitSet.unitSetCode ===
                callista[Object.keys(callista)[0]].code
              ) {
                v = aos.studyUnitSet.version
                return true
              } else {
                return false
              }
            })
          })

          break
        case 'unit':
          currentStudent.courseEnrolments.some((course) => {
            return Object.keys(course.teachingPeriods).some((tp) =>
              Object.keys(course.teachingPeriods[tp].unitEnrolments).some(
                (u) => {
                  if (u === callista[Object.keys(callista)[0]].code) {
                    v =
                      course.teachingPeriods[tp].unitEnrolments[u].studyUnit
                        .version
                    return true
                  } else {
                    return false
                  }
                }
              )
            )
          })
          break
        default:
          v = null
          break
      }
    }
    return v ? v.toString() : null
  }

  const [version, setVersion] = useState(
    historyValues?.rulesVersion || studentsVersion() || versions[0]
  )

  const changeVersion = (v) => {
    setVersion(v)
    updateHistory((page) => {
      page['rulesVersion'] = v
      return page
    })
  }

  const renderRules = () => {
    return Object.keys(callista[version].rules).map((r, i) => {
      //let title = add nice name to bigJSON
      let rule = callista[version].rules[r].split(/(&)/g)
      return (
        <section className={c.ruleText} key={i}>
          <Accordion header={<h2>{r}</h2>}>
            {rule.map((x, j) =>
              x === '&' ? (
                <div className={c.amp} key={j}>
                  {x}
                </div>
              ) : (
                <p key={j}>{x}</p>
              )
            )}
          </Accordion>
        </section>
      )
    })
  }

  // render

  return (
    <div className={c.rules}>
      <div className={c.versionSelect}>
        <span>{type} Version</span>
        <fieldset>
          <select
            onChange={(e) => changeVersion(e.target.value)}
            value={version}>
            {versions.map((v, i) => (
              <option value={v} key={i}>
                {v === studentsVersion() ? `${v} (Enrolled)` : v}
              </option>
            ))}
          </select>
          <div className={c.dropdown}>
            {version === studentsVersion() ? `${version} (Enrolled)` : version}{' '}
            <Icon.ChevronDown />
          </div>
        </fieldset>
      </div>
      {callista[version] && renderRules()}
    </div>
  )
}

export default Rules
