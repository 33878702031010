import React from 'react'
import c from './hover-menu.module.scss'

const HoverMenu = ({ title, children, onContextMenu, style }) => {
  // render

  return (
    <div className={c.hoverMenu} onContextMenu={onContextMenu} style={style}>
      {title}
      <div className={c.buttons}>{children}</div>
    </div>
  )
}

export default HoverMenu
