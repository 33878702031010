import React, { useContext, useEffect, useRef, useState } from 'react'
import { Drag } from 'components/utilities/drag-drop/DragDrop'
import Blocks from '../blocks/Blocks'
import c from './periods.module.scss'
import { nanoid } from 'nanoid'
import { Data } from 'components/wrappers/course-map-data-wrapper/CourseMapDataWrapper'
import { ContextMenuData } from 'components/wrappers/context-menu-wrapper/ContextMenuWrapper'
import Icon from 'components/utilities/icons/Icon'
import { createPortal } from 'react-dom'
import UnitSearch from './unit-search/UnitSearch'
import HoverMenu from '../hover-menu/HoverMenu'
import { Interface } from 'components/wrappers/interface-data-wrapper/InterfaceDataWrapper'
import { gradeStatus } from 'utils/data/grades'

const Period = ({ period, fake = false, year, i }) => {
  const { setMenu } = useContext(ContextMenuData)
  const { setCourseMap, autosave } = useContext(Data)
  const { editMode } = useContext(Interface)
  const {
    displayOptions: { condenseMode },
  } = useContext(Interface)

  const removePeriod = () => {
    setCourseMap((f) => {
      const newMap = { ...f }
      newMap.years[year].periods = newMap.years[year].periods.filter(
        (p) => p.id !== period.id
      )

      autosave(newMap)
      return newMap
    })
  }

  // edit name

  const [editName, setEditName] = useState(false)
  const [name, setName] = useState('')
  const nameRef = useRef(null)

  useEffect(() => {
    if (editName) nameRef.current.select()
  }, [editName])

  const startEdit = () => {
    setName(period.name)
    setEditName(true)
  }

  const changeName = (e) => {
    e.preventDefault()
    setEditName(false)

    setCourseMap((f) => {
      const newMap = { ...f }
      newMap.years[year].periods[i].name = name
      autosave(newMap)
      return newMap
    })
  }

  // add unit

  const [showSearch, setShowSearch] = useState(false)

  const addUnit = () => {
    setShowSearch(true)
  }

  const saveUnit = (unit) => {
    const unitBlock = {
      type: 'BLOCK',
      blockType: 'UNIT',
      origin: 'MAP',
      id: nanoid(),
      creditPoints: unit.creditPoints,
      description: unit.title,
      name: unit.code,
      status: 'SUGGESTED',
    }

    setCourseMap((f) => {
      const newMap = { ...f }

      newMap.years[year].periods[i].blocks.push(unitBlock)
      setShowSearch(false)

      autosave(newMap)
      return newMap
    })
  }

  // add comment

  const addComment = () => {
    console.log('yea')
    const commentBlock = {
      type: 'BLOCK',
      blockType: 'COMMENT',
      origin: 'MAP',
      id: nanoid(),
      description: 'Edit comment',
      status: 'EDIT',
    }

    setCourseMap((f) => {
      const newMap = { ...f }

      newMap.years[year].periods[i].blocks.push(commentBlock)

      autosave(newMap)
      return newMap
    })
  }

  // context menu

  const contextMenu = (e) => {
    e.preventDefault()
    setMenu({
      id: period.id,
      position: { x: e.clientX, y: e.clientY },
      items: [
        { name: 'Edit name', icon: <Icon.Pencil />, fn: startEdit },
        { name: 'Add unit', icon: <Icon.Unit />, fn: addUnit },
        { name: 'Add comment', icon: <Icon.Comment />, fn: addComment },
        { name: 'BREAK' },
        {
          name: (
            <span>
              Remove <b>{period.name}</b>
            </span>
          ),
          icon: <Icon.Trash />,
          className: period.blocks.length ? c.grey : c.red,
          fn: period.blocks.length ? null : removePeriod,
        },
      ],
    })
  }

  // check if filtered empty

  const { displayOptions } = useContext(Interface)

  const filteredBlocks = period.blocks.filter((item) => {
    if (
      item &&
      !displayOptions.showFailedUnits &&
      ['MAP', 'CREDIT'].includes(item.origin)
    ) {
      const grade = gradeStatus(item.grade)
      if (['DISCONTIN'].includes(item.status)) return false
      if (item.grade && ['FAIL', 'WITHDRAWN'].includes(grade)) return false
    }

    return true
  })

  // render fake

  if (fake) return <div className={c.period}></div>

  // render

  return (
    <Drag
      id={period.id}
      className={`${c.period} ${condenseMode ? c.condense : null}`}
      data={{ ...period, periodIndex: i, year, type: 'PERIOD' }}
      // handleClass={}
      disabled={editMode || editName || period.disabled}
      handleChildren={
        !editMode &&
        !editName &&
        !period.disabled && (
          <div className={c.handle} onContextMenu={contextMenu} />
        )
      }>
      {/* <Draggable /> */}
      {!editName &&
        (editMode || period.disabled ? (
          <h3 className={c.disabled}>{period.name}</h3>
        ) : (
          <HoverMenu
            title={<h3>{period.name}</h3>}
            style={{ paddingRight: `20px` }}>
            <button onClick={startEdit} title='Edit period name'>
              <Icon.Pencil />
            </button>
            <button onClick={addUnit} title='Add unit'>
              <Icon.Unit />
            </button>
            <button onClick={addComment} title='Add comment'>
              <Icon.Comment />
            </button>
          </HoverMenu>
        ))}
      {editName && (
        <h3>
          <form onSubmit={changeName}>
            <input
              ref={nameRef}
              autoFocus
              onBlur={changeName}
              value={name}
              onChange={(e) => {
                setName(e.target.value)
              }}
            />
          </form>
        </h3>
      )}
      <Blocks
        empty={filteredBlocks.length === 0}
        filtered={filteredBlocks}
        data={period.blocks}
        periodId={period.id}
        periodIndex={i}
        disabled={period.disabled}
        {...{ fake, year }}
      />
      {showSearch &&
        createPortal(
          <UnitSearch {...{ saveUnit, setShowSearch }} />,
          document.body
        )}
    </Drag>
  )
}

export default Period
