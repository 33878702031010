import Icon from 'components/utilities/icons/Icon'
import React from 'react'
import { capitalise } from 'utils/transforms/string-transforms'
import c from './change-summary.module.scss'

const getUnitChanges = (current, original) => {
  const additions = []
  const subtractions = []

  const check = (a, b, array) => {
    Object.keys(a.years).forEach((y) => {
      a.years[y].periods?.forEach((p) =>
        p.blocks.forEach((u) => {
          if (u?.blockType === 'UNIT') {
            if (
              !b.years[y] ||
              !b.years[y]?.periods.some((item) => item?.name === p?.name) ||
              !b.years[y]?.periods
                .filter((item) => item.name === p.name)
                .filter((fp) =>
                  fp.blocks.some((item) => item?.name === u?.name)
                ).length
            )
              array.push({ year: y, period: p.name, unit: u })
          }
        })
      )
    })
  }

  check(current, original, additions)
  check(original, current, subtractions)

  const checkAS = (a, b, array) => {
    a.advancedStandings?.forEach((u) => {
      if (u.blockType === 'CREDIT') {
        if (
          !b.advancedStandings
          || !b.advancedStandings?.some((item) => item.name === u.name)
        )
          array.push({ year: 'Credit for prior learning', period: 'Credit', unit: u })
      }
    })
  }

  checkAS(current, original, additions)
  checkAS(original, current, subtractions)

  return { additions, subtractions }
}

const getAoSChanges = (current, original) => {
  const additions = []
  const subtractions = []

  const check = (a, b, array) => {
    a.aos.forEach((item) => {
      if (!b.aos?.some((aos) => aos.code === item.code)) array.push(item)
    })
  }

  check(current, original, additions)
  check(original, current, subtractions)

  return { additions, subtractions }
}

export const compare = (current, original) => {
  return {
    aos: getAoSChanges(current, original),
    units: getUnitChanges(current, original)
  }
}

const ChangeSummaryMini = ({ current, original, comparison }) => {

  const changes = comparison ? comparison : compare(current, original)

  // render unit changes

  const renderUnitChanges = () => {
    const renderObject = {}

    const checkThenAdd = (item, type) => {
      if (!renderObject[item.year]) renderObject[item.year] = {}
      if (!renderObject[item.year][item.period])
        renderObject[item.year][item.period] = {
          additions: [],
          subtractions: [],
        }
      renderObject[item.year][item.period][type].push(
        item.unit.creditType === 'unspecifiedCredit' ?
          `Level ${item.unit.unitLevel} ${capitalise(item.unit.disciplineDescription)}`
          : `${item.unit.name} ${item.unit.description}`
      )
    }

    changes.units.additions.forEach((item) => 
      checkThenAdd(item, 'additions')
    )
    changes.units.subtractions.forEach((item) =>
      checkThenAdd(item, 'subtractions')
    )

    return Object.keys(renderObject).map((y, i) => (
      <div className={c.year} key={i}>
        <h4>{y}</h4>
        {Object.keys(renderObject[y]).map((p, j) => {
          return (
          <div className={c.teachingPeriod} key={j}>
            {p !== 'Credit' && <h5>{p}</h5>}
            {renderObject[y][p].additions.length ? (
              <ul className={c.addedUnits}>
                {renderObject[y][p].additions.map((u, k) => (
                  <li key={k}>
                    <div>
                      <Icon.Plus />
                      <span>{u}</span>
                    </div>
                  </li>
                ))}
              </ul>
            ) : null}

            {renderObject[y][p].subtractions.length ? (
              <ul className={c.subractedUnits}>
                {renderObject[y][p].subtractions.map((u, k) => (
                  <li key={k}>
                    <div>
                      <Icon.Minus />
                      <span>{u}</span>
                    </div>
                  </li>
                ))}
              </ul>
            ) : null}
          </div>
        )})}
      </div>
    ))
  }

  // render

  return (
    <div
      className={`${c.ChangeSummary} ${c.lightMode}`}
      id='change-summary'
    >
      {changes.aos.additions.length || changes.aos.subtractions.length ? (
        <>
          <h3>Areas of study</h3>
          <div className={c.changedAoS}>
            {changes.aos.additions.length ? (
              <ul className={c.addedAoS}>
                {changes.aos.additions.map((item, i) => {
                  return (
                    <li key={i}>
                      <div>
                        <Icon.Plus />
                        <span>{item.name}</span>
                      </div>
                    </li>
                  )
                })}
              </ul>
            ) : null}
            {changes.aos.subtractions.length ? (
              <ul className={c.subtractedAoS}>
                {changes.aos.subtractions.map((item, i) => {
                  return (
                    <li key={i}>
                      <div>
                        <Icon.Minus />
                        <span>{item.name}</span>
                      </div>
                    </li>
                  )
                })}
              </ul>
            ) : null}
          </div>
        </>
      ) : null}

      {changes.units.additions.length || changes.units.subtractions.length ? (
        <>
          <h3>Units</h3>
          <div className={c.changedUnits}>{renderUnitChanges()}</div>
        </>
      ) : null}
    </div>
  )
}

export default ChangeSummaryMini
