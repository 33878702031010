import React from 'react'
import VBlock from './VBlock'
import c from './view-plan.module.scss'
import { gradeStatus } from 'utils/data/grades'

const VPeriod = ({ period, showFailed }) => {
  const filteredBlocks = period.blocks.filter((item) => {
    if (item && !showFailed && ['MAP', 'CREDIT'].includes(item.origin)) {
      const grade = gradeStatus(item.grade)
      if (['DISCONTIN'].includes(item.status)) return false
      if (item.grade && ['FAIL', 'WITHDRAWN'].includes(grade)) return false
    }

    return true
  })

  const hidden = period.blocks.length - filteredBlocks.length

  // render

  return (
    <div className={c.period}>
      <h4>{period.name}</h4>
      {filteredBlocks.length > 0 ? (
        <div className={c.blocks}>
          {filteredBlocks.map((block, i) => (
            <VBlock block={block} key={i} showFailed={showFailed} />
          ))}
        </div>
      ) : (
        <div className={c.empty}>
          {period.blocks.length === 0 ? (
            'Empty'
          ) : (
            <div>
              {hidden} withdrawn or failed{' '}
              {hidden > 1 || hidden === 0 ? 'units are' : 'unit is'} hidden{' '}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default VPeriod
