import React, { useState, useLayoutEffect, useRef } from 'react'
import c from './tabs.module.scss'
import Tab from './Tab'

const Tabs = ({ onChange, tabs, activeKey = 0 }) => {
  const [indicatorProps, setIndicatorProps] = useState(null)
  const renderListRef = useRef()
  const renderList = tabs.map((item, i) => {
    return (
      <Tab
        key={i}
        label={tabs[i].name}
        active={activeKey === i}
        onClick={() => onChange(i)}></Tab>
    )
  })

  useLayoutEffect(() => {
    setIndicatorProps({
      size: renderListRef.current?.children[activeKey]?.offsetWidth,
      pos: renderListRef.current?.children[activeKey]?.offsetLeft,
    })
  }, [activeKey, tabs])

  return (
    <div className={c.tab} id='tabs'>
      <div className={c.overflowContainer}>
        <div className={c.tabContainer}>
          <ul ref={renderListRef}>{renderList}</ul>
          <div
            style={
              indicatorProps
                ? {
                    transform: `translate(${indicatorProps.pos}px,0)`,
                    width: `${indicatorProps.size}px`,
                  }
                : {}
            }
            className={c.indicator}></div>
        </div>
      </div>
    </div>
  )
}

export default Tabs
