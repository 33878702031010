import React, { useContext, useEffect, useMemo, useState } from 'react'
import flattenCourseMap from './flatten-course-map'
import { fs } from 'utils/firebase'
import c from './course-map-data-wrapper.module.scss'
import LoadingIndicator from 'components/utilities/loading-indicator/LoadingIndicator'
import { Interface } from '../interface-data-wrapper/InterfaceDataWrapper'
import { UserData } from '../user-data-wrapper/UserDataWrapper'
import { unitAos } from 'utils/services'
import cleanCourseMap from './clean-coursemap'
import { CurrentStudent } from 'components/wrappers/student-data-wrapper/StudentDataWrapper'
import { analytics } from 'utils/firebase'
import { getFacultyFromCourseCode } from 'utils/data/faculty'
import { isPass } from 'utils/data/grades'

// context
export const Data = React.createContext()

// autosave indicator
export const AutoSaveIndicator = ({ text = 'Saving' }) => {
  const { saving } = useContext(Data)

  return (
    <div className={c.autosave}>
      <div className={saving ? c.saving : c.idle}>
        <LoadingIndicator size={12} color='white' />
        <div className={c.text}>{text}</div>
      </div>
    </div>
  )
}

// wrapper
const CourseMapDataWrapper = ({ children }) => {
  const [selectedPlan, setSelectedPlan] = useState(null)
  const [selectedPMap, setSelectedPMap] = useState({})
  const [courseMap, setCourseMap] = useState(null)
  const [inEditCourseMap, setInEditCourseMap] = useState(null)
  const { user } = useContext(UserData)
  const { editMode } = useContext(Interface)
  const { currentStudent } = useContext(CurrentStudent)

  // course map
  const onDisplayCourseMap = useMemo(
    () => (editMode ? inEditCourseMap : courseMap),
    [editMode, inEditCourseMap, courseMap]
  )

  // flat course map
  const flatCourseMap = useMemo(
    () => flattenCourseMap(onDisplayCourseMap),
    [onDisplayCourseMap]
  )

  // load selected plan into course map
  const { setShowPlanningPanel } = useContext(Interface)

  useEffect(() => {
    setShowPlanningPanel(selectedPlan ? true : false)
    setCourseMap(selectedPlan)
  }, [selectedPlan, setShowPlanningPanel])
  // autosave
  const [saving, setSaving] = useState(false)
  const autosave = (data) => {
    data.logs.edited = { time: Date.now(), user: user.displayName }
    setSaving(true)

    if (flatCourseMap.some((item) => !item)) {
      data = cleanCourseMap(data)
      setCourseMap(data)
    }
    fs.collection('student-plans')
      .doc(data.id)
      .set(data)
      .then(() => {
        setSaving(false)
      })
      .catch((error) => console.log(error))
    // log event to analytics
    const userProperties = {
      id: user.id,
      name: user.displayName,
      type: 'Staff',
      faculty: getFacultyFromCourseCode(courseMap.code),
    }
    analytics.setUserProperties(userProperties)
    analytics.logEvent('planChanged', {
      studentId: courseMap.student,
      studentName:
        currentStudent.personName.givenNames
          .slice(0, currentStudent.personName.givenNames.length)
          .join(' ') +
        ' ' +
        currentStudent.personName.familyName,
      planId: courseMap.id,
      staffId: user.id,
      staffName: user.displayName,
      courseCode: courseMap.code,
      courseTitle: courseMap.title,
      faculty: getFacultyFromCourseCode(courseMap.code),
    })
  }

  //plan local storage
  const [storedPlan] = useState(
    JSON.parse(sessionStorage.getItem('selectedPlan'))
  )
  useEffect(() => {
    if (!selectedPlan && storedPlan) {
      fs.collection('student-plans')
        .doc(typeof storedPlan === 'string' ? storedPlan : storedPlan.id)
        .get()
        .then((res) => setSelectedPlan(res.data()))
    }
  }, [selectedPlan, storedPlan])

  /*useEffect(() => {
    if (courseMap)
      sessionStorage.setItem('selectedPlan', JSON.stringify(courseMap))
  }, [courseMap])*/

  // Name
  const [planName, setPlanName] = useState(null)

  useEffect(() => {
    if (selectedPlan) setPlanName(selectedPlan.planName || 'Your course map')
  }, [selectedPlan])

  // Course Labels
  const courseLabels = useMemo(() => {
    if (selectedPMap.doubleDegree) {
      const courseLabelsSet = new Set()
      selectedPMap.map.forEach((year) => {
        year.periods.forEach((periods) => {
          periods.units.forEach((unit) => {
            courseLabelsSet.add(unit.courseLabel)
          })
        })
      })
      return [...courseLabelsSet]
    }
    return null
  }, [selectedPMap])

  // AoS
  const [listOfAoS, setListOfAoS] = useState([])
  const [eligibleAoS, setEligibleAoS] = useState({})
  const [selectedAoS, setSelectedAoS] = useState([])

  useEffect(() => {
    listOfAoS.forEach((item, i) => {
      setEligibleAoS((f) => {
        const newObj = { ...f }

        if (!newObj[item.aos])
          newObj[item.aos] = {
            code: item.aos,
            type: item.aosType,
            title: item.aosName,
            start: item.aosStart,
            units: [],
          }

        const set = new Set([...newObj[item.aos].units, item.unit])

        newObj[item.aos].units = [...set]

        return newObj
      })
    })
  }, [listOfAoS])

  useEffect(() => {
    if (flatCourseMap) {
      unitAos({
        units: flatCourseMap
          .filter(
            (item) =>
              (item.grade
                ? isPass(item.grade)
                : !['DISCONTIN'].includes(item.status)) && item.name
          )
          .map((item) => item.name),
      }).then((r) => {
        setListOfAoS(r)
      })
    }
  }, [flatCourseMap])
  // render
  return (
    <Data.Provider
      value={{
        // switch between in edit and not in edit mode
        courseMap: onDisplayCourseMap,
        setCourseMap,
        flatCourseMap,
        selectedPlan,
        setSelectedPlan,
        selectedPMap,
        setSelectedPMap,
        courseLabels,
        eligibleAoS,
        selectedAoS,
        setSelectedAoS,
        autosave,
        saving,
        planName,
        setPlanName,
        inEditCourseMap,
        setInEditCourseMap,
      }}>
      {children}
    </Data.Provider>
  )
}

export default CourseMapDataWrapper
