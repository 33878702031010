const indexID = (array, item, type = 'id') => {
  const obj = {}

  // if major
  if (item.type === 'AOS') {
    obj.fullYear = Object.values(array.years).find((year) =>
      year.periods.some((period) =>
        period.blocks.some((block) => block.name === item.name)
      )
    )

    if (!obj.fullYear) return null

    if (obj.fullYear) {
      obj.year = obj.fullYear.name

      obj.period = array.years[obj.year].periods.findIndex((period) =>
        period.blocks.some((block) => block.name === item.name)
      )

      obj.block = array.years[obj.year].periods[obj.period].blocks.findIndex(
        (block) => block.name === item.name
      )
    }
  }

  // if on map
  if (item.origin === 'MAP') {
    if (item.type === 'PERIOD') {
      obj.year = Object.values(array.years).find((year) =>
        year.periods.some((period) => period[type] === item[type])
      )?.name

      obj.period = array.years[obj.year].periods.findIndex(
        (period) => period[type] === item[type]
      )
    }

    if (item.type === 'BLOCK') {
      obj.fullYear = Object.values(array.years).find((year) =>
        year.periods.some((period) =>
          period.blocks.some((block) => block[type] === item[type])
        )
      )

      obj.year = obj.fullYear?.name

      obj.period = array.years[obj.year]?.periods.findIndex((period) =>
        period.blocks.some((block) => block[type] === item[type])
      )

      obj.block = array.years[obj.year]?.periods[obj.period]?.blocks.findIndex(
        (block) => block[type] === item[type]
      )
    }
  }

  // if in browser
  if (item.origin === 'BROWSER') {
  }

  return obj
}

export default indexID
