import React from 'react'
import c from './preview.module.scss'

const Preview = ({ data }) => {
  // status
  const status = (block) => {
    if (block.status === 'COMPLETED')
      return (
        <div className={block.grade === 'N' ? c.gradedFail : c.gradedPass}>
          <span>Graded</span>
          <span>{block.grade}</span>
        </div>
      )

    if (block.status === 'ENROLLED')
      return <div className={c.enrolled}>Enrolled</div>
    if (block.status === 'NOT OFFERED')
      return <div className={c.notOffered}>Not offered</div>
    if (block.status === 'SUGGESTED')
      return <div className={c.suggested}>Suggested</div>
    if (block.status === 'DISCONTIN')
      return <div className={c.discontinued}>Discontinued</div>
    if (block.status === 'IN PLAN')
      return (
        <div className={c.inPlanStatus}>
          {block.origin === 'MAP' ? 'Credited' : 'In plan'}
        </div>
      )
  }

  // class name

  const cName = (block) => {
    const list = [c.block]

    if (block.origin === 'MAP') list.push(c.map)
    if (block.origin === 'PMAP') list.push(c.pMap)
    if (block.origin === 'BROWSER') list.push(c.browser)
    if (block.origin === 'CREDIT') list.push(c.credit)
    if (block.status === 'NEW') list.push(c.newBlock)
    if (block.status === 'SUGGESTED') list.push(c.suggested)
    if (block.status === 'COMPLETED') list.push(c.graded)
    if (block.status === 'ENROLLED') list.push(c.enrolled) //Newly added
    if (block.status === 'IN PLAN') list.push(c.inPlan)
    if (block.blockType === 'COMMENT') list.push(c.comment)

    return list.join(' ')
  }

  // render

  return (
    <div className={c.preview}>
      {Object.values(data.years).map((year, i) => (
        <div className={c.year} key={i}>
          <h3>{year.name}</h3>
          <div className={c.periods}>
            {year.periods.map((period, j) => (
              <div className={c.period} key={j}>
                <h4>{period.name}</h4>
                <div className={c.blocks}>
                  {period.blocks.map((block, k) => (
                    <div className={cName(block)} key={k}>
                      <div className={c.content}>
                        <div className={c.name}>{block.name}</div>
                        <div className={c.description}>{block.description}</div>
                      </div>
                      {block.blockType !== 'COMMENT' && <div className={c.status}>{status(block)}</div>}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export default Preview
