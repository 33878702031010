import useOnScreen from 'components/utilities/hooks/useOnScreen'
import React, { useRef } from 'react'
import c from './glossary-item.module.scss'

const GlossaryItem = ({ name, definition }) => {
  const ref = useRef()
  const onScreen = useOnScreen(ref)

  return (
    <div
      ref={ref}
      className={`${onScreen ? c.loadAnimation : c.unloadAnimation} ${
        c.glossaryItem
      }`}>
      <h2>{name}</h2>
      <p>{definition}</p>
    </div>
  )
}

export default GlossaryItem
