import React from 'react'
import c from './tutorial-graphics.module.scss'

const TutorialBookmarks = () => {
  return (
    <div className={c.containerMain}>
      <div className={c.navPanel}>
        <div className={c.panel}></div>
        <div className={`${c.panel} ${c.active}`}></div>
        <div className={c.panel}></div>
      </div>
      <div className={c.panel}>2</div>
      <div className={c.panel}>3</div>
    </div>
  )
}

export default TutorialBookmarks
