import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
  useContext,
  useLayoutEffect,
} from 'react'
import Topbar from './topbar/Topbar'
import c from './planner.module.scss'
import StudentDetails from './student-details/StudentDetails'
import Mapper from './mapper/Mapper'
import AreaOfStudy from './student-details/area-of-study/AreaOfStudy'
import { TutorialContext } from 'components/utilities/tutorial/TutorialProvider'
import { Interface } from 'components/wrappers/interface-data-wrapper/InterfaceDataWrapper'
import { Data } from 'components/wrappers/course-map-data-wrapper/CourseMapDataWrapper'
import PlanName from './plan-name/PlanName'
import ChangeSummary from './change-summary/ChangeSummary'
import Icon from 'components/utilities/icons/Icon'

export const PlannerSize = React.createContext()
export const DisplayOptions = React.createContext()

const Planner = ({ width }) => {
  const { selectedPlan, courseMap, autosave, setCourseMap } = useContext(Data)
  const [plannerSize, setPlannerSize] = useState(window.innerWidth * 0.4)
  const { inEditCourseMap, setInEditCourseMap } = useContext(Data)
  const { showPlanningPanel } = useContext(Interface)

  const observer = useMemo(
    () =>
      new ResizeObserver((entries) =>
        setPlannerSize(entries[0].contentRect.width)
      ),
    []
  )

  const measure = useCallback(
    (node) => {
      if (node) observer.observe(node)
    },
    [observer]
  )

  useEffect(() => {
    return () => observer.disconnect()
  }, [observer])

  //tutorial
  const { updateRef } = useContext(TutorialContext)
  const containerRef = useRef()

  useLayoutEffect(() => {
    updateRef('Planning panel', containerRef.current)
  }, [updateRef])

  // offset
  const { offsetPlanner, editMode, setEditMode } = useContext(Interface)
  const [offset, setOffset] = useState(0)
  const ref = useRef()

  // confirm bulk edit
  const confirmEdit = () => {
    autosave(inEditCourseMap)
    setCourseMap(inEditCourseMap)
    setInEditCourseMap(null)
    setEditMode(null)
  }

  // cancel bulk edit
  const cancelEdit = () => {
    setInEditCourseMap(null)
    setEditMode(null)
  }

  // reset when selected plan changes
  useEffect(() => {
    setInEditCourseMap(null)
    setEditMode(null)
  }, [setInEditCourseMap, setEditMode, selectedPlan])

  useEffect(() => {
    if (offsetPlanner && ref.current) {
      const offset =
        offsetPlanner - ref.current.scrollTop > 0
          ? offsetPlanner - ref.current.scrollTop - 100
          : 0

      setOffset(offset)
      setTimeout(
        () =>
          ref.current.scrollTo(
            0,
            offset
              ? offsetPlanner
              : offsetPlanner + (ref.current.scrollTop - offsetPlanner)
          ),
        0
      )
    }

    if (offsetPlanner === 0) {
      setOffset(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offsetPlanner])

  // render

  return (
    <PlannerSize.Provider value={{ plannerSize, setPlannerSize }}>
      <div ref={containerRef}>
        {selectedPlan && courseMap && (
          <div
            className={c.planner}
            id='planner'
            ref={measure}
            style={{ width: `${width}vw` }}>
            {showPlanningPanel && <Topbar />}

            <div
              className={c.plannerContent}
              ref={ref}

              // onScroll={ (e) => setScrollPos(e.target.scrollTop) }
            >
              {/* plan name */}
              <PlanName />
              {/* student details */}
              <StudentDetails top={offset} />
              {/* aos */}
              <AreaOfStudy />
              <div className={editMode ? c.editMode : c.notEditMode}>
                {editMode ? (
                  <div className={c.editModeHeading}>
                    <h3>Edit {editMode} in the following:</h3>
                    <button onClick={() => cancelEdit()}>
                      <Icon.X />
                    </button>
                  </div>
                ) : null}
                {/* mapper */}
                <Mapper />
                {editMode ? (
                  <div className={c.inEditButtons}>
                    <button
                      className={c.confirmationButton}
                      onClick={() => confirmEdit()}>
                      Confirm
                    </button>
                    <button
                      className={c.cancelButton}
                      onClick={() => cancelEdit()}>
                      Cancel
                    </button>
                  </div>
                ) : null}
              </div>
              {/* change summary */}
              <div className={c.changeSummary}>
                <ChangeSummary />
              </div>
            </div>
          </div>
        )}
      </div>
    </PlannerSize.Provider>
  )
}

export default Planner
