import React from 'react'
import Accordion from '../../modules/accordion/Accordion'
import c from './handbook.module.scss'
import HandbookBlocks from './HandbookBlocks'
import htmlParse from 'html-react-parser'

const HandbookSection = ({ data, lvl }) => {
  const sections = data
    ?.sort((a, b) => (a.order > b.order ? 1 : a.order < b.order ? -1 : 0))
    .map((item, i) => {
      const header = (
        <span className={c.accordionHeader}>
          {item.title && <h2>{item.title}</h2>}
          {item.credit_points && (
            <span className={c.cp}>{item.credit_points || 0} CP</span>
          )}
        </span>
      )

      return (
        <div className={lvl === 0 ? c.container : null} key={i}>
          <div className={c.section}>
            <Accordion header={header} noPad={lvl === 0 ? false : true}>
              <div className={c.description}>{htmlParse(item.description)}</div>
              {item?.relationship.length ? (
                <HandbookBlocks data={item.relationship} />
              ) : null}
              {item?.container.length ? (
                <HandbookSection data={item.container} lvl={lvl + 1} />
              ) : null}
            </Accordion>
          </div>
        </div>
      )
    })

  // render

  return <div className={c.HandbookSection}>{sections}</div>
}

export default HandbookSection
