import React from 'react'
import s from './intro-bookmark.module.scss'
import Icon from 'components/utilities/icons/Icon'

const IntroBookmark = () => {
  return (
    <div className={s.containerMain}>
      <div className={s.topCard}>
        <div className={s.lines}></div>
      </div>
      <div className={s.bottomCard}>
        <div className={s.lines}></div>
      </div>
      <div className={s.bottomDots}></div>
      <div className={s.iconBookmark}>
        <Icon.Bookmark />
      </div>
    </div>
  )
}

export default IntroBookmark
