import Icon from 'components/utilities/icons/Icon'
import React, { useState, useEffect, useContext } from 'react'
import c from './search-box.module.scss'
import { handbookSearch } from 'utils/services'
import { Link } from 'utils/Router'
import { RouterState } from 'utils/Router/'
import LoadingIndicator from 'components/utilities/loading-indicator/LoadingIndicator'

const SearchBox = () => {
  const [target, setTarget] = useState('')
  const [matches, setMatches] = useState([])
  const [loading, setLoading] = useState(false)
  const [showItems, setShowItems] = useState(false)
  const [active, setActive] = useState(false)
  const { redirect } = useContext(RouterState)
  const [results, setResults] = useState({})

  useEffect(() => {
    if (!target) {
      setMatches([])
      return
    }

    if (matches.length === 0) {
      setLoading(true)
    }

    if (!results[target]) {
      const debouncedSearch = setTimeout(async () => {
        const result =
          (await handbookSearch({ target: target, size: 8 })).hits?.hits || []
        setResults((f) => {
          let copy = { ...f }
          copy[target] = result
          return copy
        })
      }, 300)

      return () => clearTimeout(debouncedSearch)
    }
  }, [matches.length, results, target])

  useEffect(() => {
    if (results[target]) {
      setMatches(results[target])
      setLoading(false)

      const show = results[target].length > 0
      setShowItems(show)
    }
  }, [results, target])

  const displayResult = (data, i) => {
    const type = data.type === 'Area of Study' ? 'aos' : data.type.toLowerCase()
    return (
      <Link
        to={`/browse/${type}/${data.code}?year=${data.implementationYear}`}
        data={{ type: type, code: data.code, year: data.implementationYear }}
        className={c.hit}
        key={i}>
        <div className={c.metaTop}>
          <span className={c.type}>
            {type === 'aos'
              ? data.category.toUpperCase()
              : data.type.toUpperCase()}
          </span>{' '}
          &bull; <span className={c.cp}>{data.creditPoints || 0}CP</span> &bull;{' '}
          <span className={c.cp}>{data.implementationYear}</span>
        </div>
        <div>
          <strong>{data.code}</strong> {data.title}
        </div>
      </Link>
    )
  }

  // render

  return (
    <div
      className={c.searchBox}
      onFocus={() => {
        setActive(true)
      }}
      onBlur={() => {
        setTimeout(() => {
          setActive(false)
        }, 200)
      }}>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          if (target) {
            setActive(false)
            redirect(`search?q=${target}`)
          }
        }}>
        <Icon.Search />
        <input
          value={target}
          onChange={(e) => setTarget(e.target.value)}
          type='text'
          placeholder='Search'
        />
      </form>
      {loading && active && target && (
        <div className={c.loading}>
          <LoadingIndicator size={30} />
        </div>
      )}
      {matches && showItems && !loading && active && target && (
        <div className={c.results}>
          {matches
            .filter(
              (a, i, arr) =>
                arr.findIndex((b) => a._source.code === b._source.code) === i
            )
            .slice(0, 4)
            .map((m, i) => displayResult(m['_source'], i))}
          {/* remove thing below to kill search page */}
          {/* <Link to={`/search?q=${target}`} data={{target: target, matches: matches}} className={c.allResults}>All Results</Link> */}
          <Link to={`/search?q=${target}`} className={c.allResults}>
            All Results
          </Link>
        </div>
      )}
      {!loading && matches?.length === 0 && active && target && (
        <div className={c.loading}>
          <p>No results found</p>
        </div>
      )}
    </div>
  )
}

export default SearchBox
