import Browser from 'components/browser/Browser'
import Landing from 'components/landing/Landing'
import Planner from 'components/planner/Planner'
import Tutorial from 'components/utilities/tutorial/Tutorial'
import { TutorialProvider } from 'components/utilities/tutorial/TutorialProvider'
import DragDropWrapper from 'components/wrappers/drag-drop-wrapper/DragDropWrapper'
import { Interface } from 'components/wrappers/interface-data-wrapper/InterfaceDataWrapper'
import { CurrentStudent } from 'components/wrappers/student-data-wrapper/StudentDataWrapper'
import { UserData } from 'components/wrappers/user-data-wrapper/UserDataWrapper'
import React, { useContext, useEffect, useState } from 'react'
import { fs } from 'utils/firebase'
import c from './main.module.scss'
//import { handbookSearch, callistaDetails } from 'utils/services'

export const PanelSize = React.createContext()

const Main = () => {
  const { currentStudent } = useContext(CurrentStudent)
  const { user, setUser } = useContext(UserData)
  const [tutorialIsShown, setTutorialIsShown] = useState(false)
  const { showPlanningPanel } = useContext(Interface)
  const [panelSize, setPanelSize] = useState(
    JSON.parse(localStorage.getItem('panelSize')) || {
      priority: 'even',
      browser: 60,
      planner: 40,
    }
  )

  useEffect(() => {
    localStorage.setItem('panelSize', JSON.stringify(panelSize))
  }, [panelSize])

  useEffect(() => {
    if (user?.student) {
      window.location =
        'https://www.monash.edu/students/study-support/course-advice'
    }
  }, [user])

  useEffect(() => {
    if (user && showPlanningPanel)
      setTutorialIsShown(!user.preferences?.tutorialSeen)
  }, [showPlanningPanel, user])

  //api warm up
  useEffect(() => {
    // handbookSearch({ target: 'test', size: 1 })
    //   .then((r) => console.log(r))
    //   .catch((e) => console.log(e))
    // callistaDetails({ code: 'A2000', type: 'course'})
    //   .then((r) => console.log(r))
    //   .catch((e) => console.log(e))
  }, [])

  const handleTutorialClose = () => {
    setTutorialIsShown(false)
    const userUpdate = {}
    userUpdate['preferences.tutorialSeen'] = true
    const ref = fs.collection(user.student ? 'students' : 'staff').doc(user.id)

    ref.update(userUpdate).then(() => {
      ref.get().then((res) => setUser(res.data()))
    })
  }

  // render

  return user?.staff ? (
    <PanelSize.Provider value={{ panelSize, setPanelSize }}>
      <TutorialProvider>
        {tutorialIsShown ? <Tutorial onClose={handleTutorialClose} /> : ''}
        {currentStudent ? (
          <main className={c.main}>
            <DragDropWrapper>
              <Browser width={showPlanningPanel ? panelSize.browser : 100} />
              <Planner width={showPlanningPanel ? panelSize.planner : 0} />
            </DragDropWrapper>
          </main>
        ) : (
          <Landing />
        )}
      </TutorialProvider>
    </PanelSize.Provider>
  ) : null
}

export default Main
