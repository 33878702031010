export const getFacultyFromCourseCode = (courseCode) => {
    return faculties[courseCode[0]]
}

export const faculties = {
    A: "Arts",
    B: "Business and Economics",
    C: "Information Technology",
    D: "Education",
    E: "Engineering",
    F: "MADA (Art, Design and Architecture)",
    L: "Law",
    M: "MNHS (Medicine, Nursing and Health Sciences)",
    P: "Pharmacy and Pharmaceutical Sciences",
    S: "Science"
}