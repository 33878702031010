import React, { useState, useContext, useEffect } from 'react'
import { Data } from 'components/wrappers/course-map-data-wrapper/CourseMapDataWrapper'

import c from './plan-name.module.scss'
import HoverMenu from '../mapper/hover-menu/HoverMenu'
import Icon from 'components/utilities/icons/Icon'
import ErrorMessage from './error-message/ErrorMessage'
import Transition from 'components/utilities/transition/Transition'

const PlanName = () => {
  const { planName, setPlanName, courseMap, setCourseMap, autosave } =
    useContext(Data)
  const [editName, setEditName] = useState(false)
  const [error, setError] = useState(false)
  const startEdit = () => {
    setPlanName(planName)
    setEditName(true)
  }

  //Get Name
  const changeName = (e) => {
    e.preventDefault()

    if (!planName) {
      setError(true)
      return
    }

    setEditName(false)
    setCourseMap((f) => {
      const newMap = { ...f }
      newMap.planName = planName
      autosave(newMap)
      return newMap
    })
  }

  // Reset
  useEffect(() => {
    setError(false)
    setEditName(false)
  }, [courseMap])

  // render

  return (
    <div className={c.planNameContainer}>
      {editName && (
        <h2>
          <form onSubmit={changeName}>
            <input
              autoFocus
              value={planName}
              className={error ? c.error : null}
              onChange={(e) => {
                e.target.value && setError(false)
                setPlanName(e.target.value)
              }}
              onBlur={changeName}
            />
          </form>
        </h2>
      )}

      {!editName && (
        <HoverMenu title={<h2>{planName}</h2>}>
          <button className={c.rightMargin} onClick={startEdit}>
            <Icon.Pencil></Icon.Pencil>
          </button>
        </HoverMenu>
      )}
      <Transition show={error} enterClass={c.enter} exitClass={c.exit}>
        <ErrorMessage />
      </Transition>
    </div>
  )
}

export default PlanName
