import TextArea from 'components/utilities/text-area/TextArea'
import { Data } from 'components/wrappers/course-map-data-wrapper/CourseMapDataWrapper'
import { Interface } from 'components/wrappers/interface-data-wrapper/InterfaceDataWrapper'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { convertLinks } from 'utils/transforms/string-transforms'
import c from './note.module.scss'

const Note = ({
  note,
  block,
  editNote,
  setEditNote,
  periodIndex,
  year,
  index,
  className,
}) => {
  const { setCourseMap, autosave } = useContext(Data)
  const [text, setText] = useState(note)
  const noteRef = useRef(null)
  const {
    displayOptions: { condenseMode },
  } = useContext(Interface)

  useEffect(() => {
    if (editNote) {
      setText(block.note)
      noteRef?.current?.select()
    }
  }, [block.note, editNote])

  const handleKeypress = (e) => {
    if (e.key === 'Enter') {
      saveEdit()
    }

    if (e.key === 'Escape') {
      setText(note)
      setEditNote(false)
    }
  }

  const saveEdit = () => {
    setEditNote(false)
    setCourseMap((f) => {
      const newMap = { ...f }
      newMap.years[year].periods[periodIndex].blocks[index].note = text
      autosave(newMap)
      return newMap
    })
  }

  // render

  return (
    <div className={`${c.Note} ${condenseMode ? c.condense : null}`}>
      <div className={c.poke} />
      {editNote ? (
        <TextArea
          className={c.textArea}
          ref={noteRef}
          value={text}
          onKeyDown={handleKeypress}
          onBlur={saveEdit}
          autofocus={true}
          onChange={(e) => setText(e.target.value)}
        />
      ) : (
        <div className={c.noteContent}>{convertLinks(note)}</div>
      )}
    </div>
  )
}

export default Note
