import React, { useMemo, useState } from 'react'

export const TutorialContext = React.createContext(null)

export const TutorialProvider = ({ children }) => {
  const [tutorialValue, setTutorialValue] = useState({ refs: {} })

  const memo = useMemo(
    () => ({
      tutorialValue,
      updateRef: (stepName, ref) => {
        setTutorialValue((value) => {
          value.refs[stepName] = ref
          return value
        })
      },
    }),
    [tutorialValue]
  )

  return (
    <TutorialContext.Provider value={memo}>{children}</TutorialContext.Provider>
  )
}
