import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useDroppable,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import React, { useContext, useEffect } from 'react'
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { DragDropData } from 'components/wrappers/drag-drop-wrapper/DragDropWrapper'
import c from './drag-drop.module.scss'

// drag drop

const DragDrop = ({ children, onDragStart, onDragOver, onDragEnd }) => {
  const sensors = useSensors(
    useSensor(MouseSensor, { delay: 1000, tolerance: 5 }),
    useSensor(TouchSensor, { delay: 1000, tolerance: 5 }),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  )

  const collisionDetection = closestCenter

  // render

  const modifiers = []

  return (
    <DndContext
      {...{
        sensors,
        collisionDetection,
        onDragStart,
        onDragOver,
        onDragEnd,
        modifiers,
      }}>
      {children}
    </DndContext>
  )
}

export default DragDrop

// drag

export const Drag = ({
  children,
  className,
  data,
  disabled = false,
  handleClass,
  handleChildren,
  id,
  style,
  contextMenu = null,
}) => {
  const {
    attributes,
    isDragging,
    listeners,
    rect,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id, disabled, data })

  const { selected, setSelectedProps, setSelected } = useContext(DragDropData)

  style = {
    ...style,
    transform: CSS.Transform.toString(transform),
    transition: transform ? transition : null,
  }

  useEffect(() => {
    let mounted = true
    if (isDragging && mounted) {
      setSelectedProps(rect.current)
      if (selected?.id !== data?.id) setSelected(data)
    }

    return () => (mounted = false)
  }, [data, isDragging, rect, selected, setSelected, setSelectedProps])

  const handleHover = (e) => {
    // console.log(e.target.getBoundingClientRect())
  }

  return (
    <div
      onContextMenu={contextMenu}
      ref={setNodeRef}
      {...{ className, style }}
      onMouseEnter={handleHover}>
      <div className={handleClass} {...attributes} {...listeners}>
        {handleChildren}
      </div>
      {children}
    </div>
  )
}

// drop

export const Drop = ({
  children,
  className,
  id,
  data,
  disabled,
  style,
  styleBefore = {},
  styleAfter = {},
}) => {
  const { isOver, setNodeRef } = useDroppable({ id, disabled, data })
  style = isOver ? { ...style, styleAfter } : { ...style, styleBefore }

  return (
    <div ref={setNodeRef} {...{ className, style }}>
      <b className={c.hidden}>Ignore me</b>
      {children}
    </div>
  )
}

// sort

export const Sort = ({ children, items, id }) => {
  return <SortableContext {...{ items, id }}>{children}</SortableContext>
}
