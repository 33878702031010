import Icon from 'components/utilities/icons/Icon'
import React from 'react'
import c from './error-message.module.scss'

const ErrorMessage = ({ className }) => {
  return (
    <div className={[c.errorMessage, className].join(' ')}>
      <div className={c.box}>
        <div className={c.poke} />
        <Icon.Alert />
        Plan name is required
      </div>
    </div>
  )
}

export default ErrorMessage
