import { getFetchOptions, call, endpointPrefix } from "./callApi"
import { fs } from './firebase'
import { getStudentInformation } from './mix'

const getStudentAdvisorBoard = async (studentNo) => {
  const juvoApiUrl = `${endpointPrefix}/juvo-api/apps/advisorBoard/v1/students/${studentNo}`
  const options = await getFetchOptions('GET')
  return await call(juvoApiUrl, options)
}

const handbookSearch = async (query) => {
  //query => {target, size, ...filters}
  const params = Object.keys(query)
    .filter((p) => query[p])
    .map((p) => `${p}=${query[p]}`)
    .join('&')
  const search = `${endpointPrefix}/handbook-search-api/handbook-query/?${params}`
  // const search = `https://nerv-uat.m.monash/handbook-search-api/handbook-query/?${params}`
  // const search = `https://nerv-handbook-search-hbdezar3cq-ts.a.run.app/handbook-query/?${params}`
  // const search = `https://monash-course-advice-uat.appspot.com/handbook-search-api/handbook-query/?${params}`
  const options = await getFetchOptions('GET')
  return await call(search, options)
}

const handbookRetrieve = async (data) => {
  //const index = data.type === 'aos' ? 'area_of_study' : data.type
  const search = `${endpointPrefix}/handbook-search-api/handbook-retrieve/?code=${data.code}&implementationYear=${data.year}`
  const options = await getFetchOptions('GET')
  return await call(search, options)
}

const handbookFilters = async () => {
  const filters = `${endpointPrefix}/handbook-search-api/handbook-getFilters`
  const options = await getFetchOptions('GET')
  return await call(filters, options)
}

const callistaDetails = async (data = { code: 'B2000', type: 'course' }) => {
  const type = data.type === 'aos' ? 'unit-set' : data.type
  const url = `${endpointPrefix}/course-rules-insight-api/get?code=${data.code}&type=${type}`
  const options = await getFetchOptions('GET')
  return await call(url, options)
}

const unitLevelMax = async (data = { code: 'B2000' }) => {
  const url = `${endpointPrefix}/course-rules-insight-api/max-credit-points-per-level?code=${data.code}`
  const options = await getFetchOptions('GET')
  return await call(url, options)
}

const unitAos = async (data = { units: [] }) => {
  let url
  if (data.units) {
    url = `${endpointPrefix}/course-rules-insight-api/aos-for-units?unitCodes=${data.units.join(',')}`
  } else if (data.aos) {
    url = `${endpointPrefix}/course-rules-insight-api/aos-for-units?aos=${data.aos.join(',')}`
  } else {
    return []
  }
  const options = await getFetchOptions('GET')
  return await call(url, options)
}

const getProgressionMaps = async (data = { filters: [] }) => { //filters: [{key, value}]
  // let url = 'https://progression-map-service-dot-monash-nerv-dev.ts.r.appspot.com/progression-maps'
  let url = `${endpointPrefix}/progression-map-api/progression-maps`
  if (data.filters?.length > 0) {
    url = `${url}?${data.filters.map(f => `${f.key}=${f.value}`).join('&')}`
  }
  const options = await getFetchOptions('GET')
  return await call(url, options)
}

const getProgressionMapFilters = async (data = { filters: [] }) => { //filters: [{key, value}]
  // let url = 'https://progression-map-service-dot-monash-nerv-dev.ts.r.appspot.com/progression-maps'
  let url = `${endpointPrefix}/progression-map-api/progression-maps/filters`
  if (data.filters?.length > 0) {
    url = `${url}?${data.filters.map(f => `${f.key}=${f.value}`).join('&')}`
  }
  const options = await getFetchOptions('GET')
  return await call(url, options)
}

const getStudentData = async (studentId) => {
  // try fetching student data from cache
  const doc = await fs.collection('students').doc(studentId.toString()).get()
  let result = doc.exists ? doc.data() : undefined

  // fetch from MIX if cache is not there
  result = result || await getStudentInformation(studentId)
  return result
}

export {
  getStudentAdvisorBoard,
  handbookSearch,
  handbookRetrieve,
  handbookFilters,
  callistaDetails,
  unitLevelMax,
  unitAos,
  getProgressionMaps,
  getProgressionMapFilters,
  getStudentData,
}
