import React from 'react'
import { Drop, Sort } from 'components/utilities/drag-drop/DragDrop'
import Period from './Period'
import c from './periods.module.scss'

const Periods = ({ data, yearId, year }) => {
  // render
  return (
    <div className={c.periods}>
      <Sort items={data.map((period) => period.id)} id={year}>
        <Drop id={`${yearId}`}>
          {data.map((period, i) => (
            <Period key={i} {...{ period, year, i }} />
          ))}
        </Drop>
      </Sort>
    </div>
  )
}

export default Periods
