import Icon from 'components/utilities/icons/Icon'
import TextArea from 'components/utilities/text-area/TextArea'
import { ContextMenuData } from 'components/wrappers/context-menu-wrapper/ContextMenuWrapper'
import { Data } from 'components/wrappers/course-map-data-wrapper/CourseMapDataWrapper'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { convertLinks } from 'utils/transforms/string-transforms'
import c from './change-summary.module.scss'

const ChangeSummaryComment = ({ data, index, viewOnly }) => {
  const { setCourseMap, autosave } = useContext(Data)
  const { setMenu } = useContext(ContextMenuData)
  const [editMode, setEditMode] = useState(data.status === 'EDIT')
  const [content, setContent] = useState(data.content)
  const commentRef = useRef(null)

  const saveEdit = () => {
    setCourseMap((f) => {
      const newMap = { ...f }
      const ref = newMap.changeSummary.comments[data.type][index]
      ref.content = content
      ref.status = 'DISPLAY'
      setEditMode(false)
      autosave(newMap)
      return newMap
    })
  }

  const editComment = () => {
    setEditMode(true)
  }

  useEffect(() => {
    if (editMode) commentRef?.current?.select()
  }, [editMode])

  const handleKeypress = (e) => {
    if (e.key === 'Enter') {
      saveEdit()
    }

    if (e.key === 'Escape') {
      setContent(data.content)
      setEditMode(false)
    }
  }

  const removeComment = () => {
    setCourseMap((f) => {
      const newMap = { ...f }
      newMap.changeSummary.comments[data.type].splice(index, 1)
      autosave(newMap)
      return newMap
    })
  }

  const contextMenu = (e) => {
    e.preventDefault()

    setMenu({
      position: { x: e.clientX, y: e.clientY },
      items: [
        {
          name: 'Edit comment',
          icon: <Icon.Pencil />,
          fn: editComment,
        },
        {
          name: 'Remove comment',
          icon: <Icon.Trash />,
          className: c.red,
          fn: removeComment,
        },
      ],
    })
  }

  // render

  return (
    <div
      className={c.ChangeSummaryComment}
      onContextMenu={viewOnly ? null : contextMenu}>
      {editMode ? (
        <TextArea
          autoFocus
          className={c.textArea}
          onChange={(e) => setContent(e.target.value)}
          onKeyDown={handleKeypress}
          onBlur={saveEdit}
          ref={commentRef}
          value={content}
        />
      ) : (
        convertLinks(data.content) || 'Comment'
      )}
    </div>
  )
}

export default ChangeSummaryComment
