// capitalise
export const capitalise = (string) =>
  string ? string[0].toUpperCase() + string.substring(1).toLowerCase() : ''

// convert links
export const convertLinks = (string) => {
  const rx =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gim

  const stringAsList = string?.split(rx) || null

  if (stringAsList)
    stringAsList.forEach((item, i) => {
      if (rx.test(item))
        stringAsList[i] = (
          <a href={item} target='_blank' rel='noreferrer'>
            {item}
          </a>
        )
    })

  return stringAsList || string
}
