import React from 'react'
import PMapSection from './PMapSection'
import c from './progression-map.module.scss'

const PMapTable = ({ data, remaining }) => {
  // render

  return (
    <div className={c.PMapTable}>
      {data.map.map((year, i) => {
        return (
          <PMapSection
            key={i}
            year={year.year}
            data={Object.values(year.periods)}
            remaining={remaining}
          />
        )
      })}
    </div>
  )
}

export default PMapTable
