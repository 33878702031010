import React, { useEffect, useState } from 'react'
import { auth, fs } from 'utils/firebase'
import LoadingScreen from 'components/utilities/loading-indicator/LoadingScreen'

export const UserData = React.createContext()

const UserDataWrapper = ({ children }) => {
  const [user, setUser] = useState(null)
  const [loggingIn, setLoggingIn] = useState(false)

  useEffect(() => {
    auth.onAuthStateChanged((authUser) => {
      //console.log('logging in')
      setLoggingIn(true)
      if (authUser) {
        authUser.getIdTokenResult().then((r) => {
          const studentNo = r.claims['callistaPersonID']
          const staffNo = r.claims['sapEmployeeID']

          const student = studentNo || false
          const staff = staffNo || false

          const ref = fs
            .collection(student ? 'students' : 'staff') // TODO: if (student && staff)
            .doc(studentNo || staffNo)

          ref.get().then((res) => {
            const userData = res.data()
            if (!userData) {
              const newUser = {
                email: authUser.email,
                id: studentNo || staffNo,
                displayName: authUser.displayName,
                monashObjectID: r.claims.monashObjectID,
                monashPersonID: r.claims.monashPersonID,
                student,
                staff,
              }
              //console.log('WTH', newUser)
              ref.set(newUser)
              setUser(newUser)
            } else {
              //console.log('WTH', userData)
              setUser(userData)
            }
            setLoggingIn(false)
          })
        })
      }
    })
  }, [])

  const [recentlyViewed, setRecentlyViewed] = useState([])

  const updateRecentlyViewed = (data) => {
    if (user && data.identifiers) {
      setRecentlyViewed((f) => {
        const newList = f ? [...f] : []

        const index = newList.findIndex(
          (item) => item.studentNo === data.identifiers?.callistaPersonID
        )

        if (index > -1) {
          newList[index].time = Date.now()
          if (newList[index].name.includes("undefined")) {
            newList[index].name = `${(data.personName?.givenNames || []).join(' ')} ${
              data.personName?.familyName
            }`
          }
          newList.sort((a, b) => b.time - a.time)
        } else {
          if (newList.length > 2) newList.pop()

          const recent = {
            email: data.identifiers.mail,
            name: `${(data.personName?.givenNames || []).join(' ')} ${
              data.personName?.familyName
            }`,
            uid: data.identifiers?.monashObjectID,
            studentNo: data.identifiers?.callistaPersonID,
            time: Date.now(),
          }

          newList.unshift(recent)
        }

        fs.collection(user.student ? 'students' : 'staff')
          .doc(user.id)
          .update({ recentlyViewed: newList })

        return newList
      })
    }
  }

  useEffect(() => {
    if (user && user.recentlyViewed) {
      setRecentlyViewed(user.recentlyViewed)
    }
  }, [user])

  // render

  return (
    <UserData.Provider
      value={{ user, setUser, recentlyViewed, updateRecentlyViewed }}>
      <LoadingScreen loaded={!loggingIn} message='Logging in'>
        {children}
      </LoadingScreen>
    </UserData.Provider>
  )
}

export default UserDataWrapper
