import React, { useEffect, useState } from 'react'
import c from './loading-indicator.module.scss'
import LoadingIndicator from './LoadingIndicator'
import LoadingPlan from './LoadingPlan'

const LoadingScreen = ({ children, message, loaded, type, style }) => {
  const [mount, setMount] = useState(false)

  useEffect(() => {
    if (!!loaded) {
      setTimeout(() => setMount(true), 10)
    } else {
      setMount(false)
    }
    // setMount(false)
  }, [loaded])

  const animation = () => {
    if (type === 'plan') {
      return <LoadingPlan />
    }
    return <LoadingIndicator size={60} />
  }

  // render

  return (
    <>
      {mount ? (
        children
      ) : (
        <div
          className={c.loadingScreen}
          style={{ ...style, opacity: loaded ? 0 : 1 }}>
          <div className={c.loadingGraphic}>{animation()}</div>
          <div className={c.message}>{message}</div>
        </div>
      )}
    </>
  )
}

export default LoadingScreen
