import Accordion from 'components/browser/content/modules/accordion/Accordion'
import Topnav from 'components/browser/topnav/Topnav'
import ChangeSummary from 'components/planner/change-summary/ChangeSummary'
import Icon from 'components/utilities/icons/Icon'
import LoadingScreen from 'components/utilities/loading-indicator/LoadingScreen'
import Toggle from 'components/utilities/toggle/Toggle'
import { Data } from 'components/wrappers/course-map-data-wrapper/CourseMapDataWrapper'
import { UserData } from 'components/wrappers/user-data-wrapper/UserDataWrapper'
import { format } from 'date-fns'
import React, { useContext, useEffect, useState } from 'react'
import { fs } from 'utils/firebase'
import { RouterState } from 'utils/Router'
import VAdvancedStandings from './view-plan/VAdvancedStandings'
import VYear from './view-plan/VYear'
import c from './view.module.scss'
import { analytics } from 'utils/firebase'
import { getFacultyFromCourseCode } from 'utils/data/faculty'
import ProgressBar from 'components/browser/content/pages/overview/progress-bar/ProgressBar'
import AosTag from 'components/planner/student-details/area-of-study/AosTag/AosTag'
import { Interface } from 'components/wrappers/interface-data-wrapper/InterfaceDataWrapper'
import { capitalise } from 'utils/transforms/string-transforms'
import { studyTypeText } from 'utils/data/studyType'

const View = () => {
  const { route } = useContext(RouterState)
  const { user } = useContext(UserData)
  const { setCourseMap } = useContext(Data)
  const { studentDisplayOptions, setStudentDisplayOptions } =
    useContext(Interface)
  const [plan, setPlan] = useState(null)
  const [student, setStudent] = useState(null)
  const [invalidPlan, setInvalidPlan] = useState(false)
  const [notAuthorised, setNotAuthorised] = useState(false)
  const [loading, setLoading] = useState('Loading plan')
  //TODO: use interface context for showFailed
  const [showFailed, setShowFailed] = useState(false)
  const [emptyChangeSummary, setEmptyChangeSummary] = useState(false)

  useEffect(() => {
    if (plan && loading === 'Loading plan') {
      setLoading('Loading student data')
      fs.collection('students')
        .doc(plan.student)
        .get()
        .then((res) => {
          setCourseMap(plan)
          setStudent(res.data())
          setLoading(false)
        })
    }
  }, [plan, setCourseMap, loading])

  useEffect(() => {
    if (user && student) {
      if (
        student.identifiers?.callistaPersonID === user.id ||
        student.identifiers?.callistaPersonID ===
          user.identifiers?.callistaPersonID
      ) {
        setCourseMap((f) => {
          const newMap = { ...f }
          newMap.logs.viewed = Date.now()
          fs.collection('student-plans')
            .doc(newMap.id)
            .set(newMap)
            .then(() => {
              // log event to analytics
              const userProperties = {
                id: student.identifiers.callistaPersonID,
                name:
                  student.personName.givenNames
                    .slice(0, student.personName.givenNames.length)
                    .join(' ') +
                  ' ' +
                  student.personName.familyName,
                type: 'Student',
                faculty: getFacultyFromCourseCode(newMap.code),
              }
              analytics.setUserProperties(userProperties)
              analytics.logEvent('studentView', {
                studentId: student.identifiers.callistaPersonID,
                studentName:
                  student.personName.givenNames
                    .slice(0, student.personName.givenNames.length)
                    .join(' ') +
                  ' ' +
                  student.personName.familyName,
                planId: newMap.id,
                courseCode: newMap.code,
                courseTitle: newMap.title,
                faculty: getFacultyFromCourseCode(newMap.code),
              })
            })
          //autosave(newMap)
          return newMap
        })
      }
    }
  }, [user, student, setCourseMap])

  useEffect(() => {
    const pathSections = route.path.split('/')
    if (pathSections[1] === 'view' && pathSections[2]) {
      fs.collection('student-plans')
        .doc(pathSections[2])
        .get()
        .then((res) => {
          const data = res.data()
          if (data) setPlan(data)
          if (!data) {
            setInvalidPlan(true)
            setLoading(false)
          }
        })
        .catch((err) => {
          setNotAuthorised(true)
          setLoading(false)
        })
    }
  }, [route, user])

  // render plan

  const renderAoS = plan
    ? plan.aos
        .filter((item) => item.name.slice(0, 2) !== 'PR')
        .map((item, i) => {
          return (
            <AosTag key={i} aos={item} noClick>
              {item.name}
            </AosTag>
          )
        })
    : null

  const renderPlan = plan
    ? Object.values(plan.years).map((year, i) => {
        return <VYear year={year} key={i} showFailed={showFailed} />
      })
    : null

  // render

  return (
    <>
      <Topnav noProfile />
      <div className={c.view}>
        <LoadingScreen loaded={!loading} message={loading} type='plan'>
          {!invalidPlan && plan && student && (
            <>
              {/* info */}
              <section className={c.info}>
                <Accordion header={<h2>Your information</h2>}>
                  <div className={c.content}>
                    <h3>
                      {student.personName.givenNames.join(' ').toLowerCase()}{' '}
                      {student.personName.familyName.toLowerCase()} {'\u2013'}{' '}
                      {plan.student}
                    </h3>

                    <ul className={c.meta}>
                      {/* course */}
                      <li>
                        <label>Course</label>
                        <div>
                          {plan.code}.{plan.version} {'\u2013'} {plan.title}
                        </div>
                      </li>

                      {/* offering option */}
                      <li>
                        <label>Location - Study type</label>
                        <div>
                        {capitalise(plan.location?.id)} {'\u2013'}{' '}
                          {studyTypeText(plan.location.attendanceType)}
                        </div>
                      </li>

                      {/* enrolment status */}
                      <li>
                        <label>Enrolment status</label>
                        <div>{capitalise(plan.status || '')}</div>
                      </li>

                      {/* start date */}
                      <li>
                        <label>Commenced date</label>
                        {student.courseEnrolments && (
                          <div>
                            {format(new Date(plan.enrolmentDate), 'd MMM yyyy')}
                          </div>
                        )}
                      </li>

                      {/* WAM/GPA */}
                      <li>
                        <label>WAM</label>
                        <div>
                          {plan.progression.weightedAverageMark || 'NA'}
                        </div>
                      </li>
                    </ul>
                  </div>
                </Accordion>
              </section>

              {/* info */}
              <section className={c.info}>
                <Accordion header={<h2>Your progress</h2>}>
                  <div className={c.content}>
                    <h2>
                      Completion progress: {plan.progression.percentCompleted}%
                      ( {plan.progression.creditPointsTotalAchieved}/
                      {plan.progression.creditPointsRequired} credit points )
                    </h2>
                    <ProgressBar
                      adv={parseInt(
                        plan.progression.creditPointsAdvancedStanding
                      )}
                      comp={parseInt(plan.progression.creditPointsPassed)}
                      enr={
                        parseInt(plan.progression.creditPointsCurrentEnrolled) +
                        parseInt(plan.progression.creditPointsFutureEnrolled)
                      }
                      total={parseInt(plan.progression.creditPointsRequired)}
                    />
                    <p className={c.disclaimer}>
                      Disclaimer: This shows your (not our) progression towards
                      meeting the credit points for course completion. Your
                      faculty will confirm whether you have successfully met all
                      other requirements.
                    </p>
                  </div>
                </Accordion>
              </section>

              {/* map */}
              <section className={c.map}>
                <Accordion
                  header={<h2>{plan.planName || 'Your course map'}</h2>}>
                  <div className={c.content}>
                    <p>
                      We’ve created a course map based on your enquiry, and this
                      year’s unit offerings.
                    </p>
                    <p>
                      Created on{' '}
                      <b>
                        {format(
                          new Date(plan.logs.created.time || plan.logs.created),
                          'do MMM yyyy'
                        )}
                      </b>
                      .
                    </p>
                    <p className={c.disclaimer}>
                      You’ll need to confirm the units and prerequisites in
                      October when the new Handbook is published.
                    </p>

                    {/* message about hidden units */}
                    <div className={c.optionMessage}>
                      <div className={c.toggleOption}>
                        <span>View withdrawn or failed units </span>
                        <Toggle
                          onClick={() => setShowFailed(!showFailed)}
                          on={showFailed}
                        />
                      </div>
                    </div>

                    {/* message about credit points */}
                    <div className={c.optionMessage}>
                      <div className={c.toggleOption}>
                        <span>View 6CP tags </span>
                        <Toggle
                          onClick={() =>
                            setStudentDisplayOptions((f) => {
                              return {
                                ...f,
                                show6CPTags: !studentDisplayOptions.show6CPTags,
                              }
                            })
                          }
                          on={studentDisplayOptions.show6CPTags}
                        />
                      </div>
                    </div>

                    {/* aos */}
                    <div className={c.areasOfStudy}>
                      <label>Areas of study</label>
                      <ul>{renderAoS}</ul>
                    </div>

                    {/* plan */}

                    <div className={c.plan}>
                      <div className={c.years}>
                        {plan.advancedStandings?.length > 0 && (
                          <VAdvancedStandings
                            advancedStandings={plan.advancedStandings}
                          />
                        )}
                        {renderPlan}
                      </div>
                    </div>
                  </div>
                </Accordion>
              </section>

              {/* change summary */}
              {!plan.hideFromStudentView && (
                <section
                  className={c.changeSummary}
                  style={{ display: emptyChangeSummary ? 'none' : 'block' }}>
                  <Accordion header={<h2>Change summary</h2>}>
                    <div className={c.content}>
                      <ChangeSummary {...{ setEmptyChangeSummary }} />
                    </div>
                  </Accordion>
                </section>
              )}

              {/* guide */}
              <section className={c.guide}>
                <Accordion header={<h2>Guide and glossary</h2>}>
                  <div className={c.content}>
                    <div className={c.graphic}>
                      <div className={c.block}>
                        <h5>
                          UNIT1234
                          <div className={[c.tip, c.left].join(' ')}>
                            Unit code and name
                            <Icon.SquiggleyArrowLeft />
                          </div>
                        </h5>
                        <div className={c.description}>Unit name</div>
                        <div className={c.aos}>
                          <span>MA1234</span>
                          <div className={[c.tip, c.right].join(' ')}>
                            <Icon.SquiggleyArrowLeft />
                            Areas of study code
                          </div>
                        </div>
                        <div className={c.status}>
                          <span>Graded</span>
                          <span>HD</span>
                          <div className={[c.tip, c.right].join(' ')}>
                            <Icon.SquiggleyArrowLeft />
                            Grade
                          </div>
                          <div className={[c.tip, c.left].join(' ')}>
                            Enrolment status
                            <Icon.SquiggleyArrowLeft />
                          </div>
                        </div>
                      </div>
                    </div>

                    <dl className={c.statusGlossary}>
                      <dt className={c.enrolled}>Enrolled</dt>
                      <dd>Enrolment has been confirmed.</dd>

                      <dt className={c.gradedPass}>Graded</dt>
                      <dd>
                        The unit has been completed with a mark and/or grade.
                      </dd>

                      <dt className={c.gradedFail}>Graded {'\u2013'} N</dt>
                      <dd>Unit has been completed and failed.</dd>

                      <dt className={c.suggested}>Suggested</dt>
                      <dd>
                        The course advisor has suggested this unit for you to
                        enrol into now or in a future semester.
                      </dd>

                      <dt className={c.duplicate}>Duplicate</dt>
                      <dd>
                        An exempted unit that has been completed and transferred
                        from another course with credit points applied to your
                        course progression if the unit was previously passed
                        with a pass mark.
                      </dd>

                      <dt className={c.discontinued}>Discontinued</dt>
                      <dd>Unit has been withdrawn.</dd>

                      <dt className={c.invalid}>Invalid</dt>
                      <dd>In breach of course or unit rules.</dd>

                      <dt className={c.granted}>Credit {'\u2013'} granted</dt>
                      <dd>
                        Credit for the unit or unit level has been approved and
                        the credit points have been applied to your study
                        progression.
                      </dd>

                      <dt className={c.granted}>Credit {'\u2013'} approved</dt>
                      <dd>
                        Credit for the unit or unit level has been approved but
                        the credit points have not yet been applied to your
                        study progression.
                      </dd>

                      <dt className={c.granted}>
                        Preclusion {'\u2013'} granted
                      </dt>
                      <dd>
                        Exempted from having to study a particular unit or part
                        of a unit level (based on prior study), but you will be
                        required to study another unit in its place. No Credit
                        Points will be granted for this type of exempted unit.
                      </dd>

                      <dt className={c.granted}>
                        Preclusion {'\u2013'} approved
                      </dt>
                      <dd>
                        Exempted from having to study a particular unit or part
                        of a unit level (based on prior study), but you will be
                        required to study another unit in its place. No Credit
                        Points will be granted for this type of exempted unit.
                      </dd>
                    </dl>
                  </div>
                </Accordion>
              </section>
              <div className={c.footer}>
                <p>
                  Monash University values the privacy of every individual’s
                  personal information and is committed to the protection of
                  that information from unauthorised use and disclosure except
                  where permitted by law. For information about the handling of
                  your personal information please see the{' '}
                  <a
                    href='https://www.monash.edu/__data/assets/pdf_file/0011/1595270/Student-Data-Management-and-Privacy-Collection-Statement.pdf'
                    target='_blank'
                    rel='noreferrer'>
                    Student Data Protection and Privacy Collection Statement
                  </a>
                  . For more information about Data Protection and Privacy at
                  Monash University please see our{' '}
                  <a
                    href='https://www.monash.edu/__data/assets/pdf_file/0003/790086/Privacy.pdf'
                    target='_blank'
                    rel='noreferrer'>
                    Data Protection and Privacy Procedure
                  </a>
                  .
                </p>

                <p>
                  If you have any questions about how Monash University is
                  collecting and handling your personal information, please
                  contact our Data Protection and Privacy Office at{' '}
                  <a href='mailto:dataprotectionofficer@monash.edu'>
                    dataprotectionofficer@monash.edu
                  </a>
                  .
                </p>
              </div>
            </>
          )}
          {invalidPlan && (
            <div className={c.invalidPlan}>Plan does not exit.</div>
          )}
          {notAuthorised && (
            <div className={c.notAuthorised}>
              You are not authorised to view this plan. Please make sure you
              have used the correct link.
            </div>
          )}
        </LoadingScreen>
      </div>
    </>
  )
}

export default View
