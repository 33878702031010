import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/firestore'
import 'firebase/analytics'
import { proxy, functions_region } from '@monash/portal-auth'
import { firebaseConfig, proxyConfig } from './config'

const fb = firebase.initializeApp(firebaseConfig)
const fbProxy = proxy(fb)
const fs = proxyConfig ? fbProxy.firestore(proxyConfig['firestoreHost']) : fb.firestore()
const auth = proxyConfig ? fbProxy.auth(proxyConfig['authHost']) : fb.auth()
const fn = proxyConfig ? fbProxy.functions(proxyConfig['functionsHost']) : fb.functions(functions_region)
const analytics = firebase.analytics(fb)

const arrayUnion = firebase.firestore.FieldValue.arrayUnion
const arrayRemove = firebase.firestore.FieldValue.arrayRemove

export default fb
export { fs, auth, fn, analytics, arrayUnion, arrayRemove }
