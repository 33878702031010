import Icon from 'components/utilities/icons/Icon'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import c from './accordion.module.scss'

const Accordion = ({
  children,
  header,
  className,
  style,
  innerStyle,
  noPad,
  onClick,
  expanded = true,
}) => {
  const [collapsed, setCollapsed] = useState(!expanded)
  const [height, setHeight] = useState(0)

  // observer

  const observer = useMemo(
    () =>
      new ResizeObserver((entries) =>
        setHeight(entries[0].target.scrollHeight)
      ),
    []
  )

  const body = useCallback(
    (node) => {
      if (node) observer.observe(node)
    },
    [observer]
  )

  useEffect(() => {
    return () => observer.disconnect()
  }, [observer])

  // handle click

  const handleClick = () => {
    onclick && onClick()
    setCollapsed(!collapsed)
  }

  // render

  return (
    <div className={`${c.accordion} ${className}`} style={style}>
      <button className={c.header} onClick={handleClick}>
        <span className={c.headerContent}>{header}</span>
        <span
          className={c.toggle}
          style={{ transform: `rotate(${collapsed ? 540 : 0}deg)` }}>
          <Icon.ChevronUp />
        </span>
      </button>

      <div className={c.body} style={{ height: collapsed ? 0 : `${height}px` }}>
        <div
          className={c.content}
          ref={body}
          style={{
            opacity: collapsed ? 0 : 1,
            transitionDelay: collapsed ? 0 : '.3s',
            paddingBottom: noPad ? 0 : '20px',
            ...innerStyle,
          }}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Accordion
