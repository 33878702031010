export const colorsList = [
  { base: '#D32F2F', contrast: '#FFFFFF' },
  { base: '#DD1144', contrast: '#FFFFFF' },
  { base: '#D50000', contrast: '#FFFFFF' },
  { base: '#D81B60', contrast: '#FFFFFF' },
  { base: '#880E4F', contrast: '#FFFFFF' },
  { base: '#C51162', contrast: '#FFFFFF' },
  { base: '#AB47BC', contrast: '#FFFFFF' },
  { base: '#7B1FA2', contrast: '#FFFFFF' },
  { base: '#4A148C', contrast: '#FFFFFF' },
  { base: '#AA00FF', contrast: '#FFFFFF' },
  { base: '#7E57C2', contrast: '#FFFFFF' },
  { base: '#5E35B1', contrast: '#FFFFFF' },
  { base: '#311B92', contrast: '#FFFFFF' },
  { base: '#7C4DFF', contrast: '#FFFFFF' },
  { base: '#6200EA', contrast: '#FFFFFF' },
  { base: '#5C6BC0', contrast: '#FFFFFF' },
  { base: '#3949AB', contrast: '#FFFFFF' },
  { base: '#1A237E', contrast: '#FFFFFF' },
  { base: '#304FFE', contrast: '#FFFFFF' },
  { base: '#1976D2', contrast: '#FFFFFF' },
  { base: '#0D47A1', contrast: '#FFFFFF' },
  { base: '#01579B', contrast: '#FFFFFF' },
  { base: '#006064', contrast: '#FFFFFF' },
  { base: '#00796B', contrast: '#FFFFFF' },
  { base: '#004D40', contrast: '#FFFFFF' },
  { base: '#2E7D32', contrast: '#FFFFFF' },
  { base: '#1B5E20', contrast: '#FFFFFF' },
  { base: '#827717', contrast: '#FFFFFF' },
  { base: '#413A00', contrast: '#FFFFFF' },
  { base: '#B05607', contrast: '#FFFFFF' },
  { base: '#BF360C', contrast: '#FFFFFF' },
  { base: '#851F00', contrast: '#FFFFFF' },
  { base: '#8D6E63', contrast: '#FFFFFF' },
  { base: '#6D4C41', contrast: '#FFFFFF' },
  { base: '#4E342E', contrast: '#FFFFFF' },
  { base: '#3E2723', contrast: '#FFFFFF' },
  { base: '#757575', contrast: '#FFFFFF' },
  { base: '#424242', contrast: '#FFFFFF' },
  { base: '#212121', contrast: '#FFFFFF' },
  { base: '#546E7A', contrast: '#FFFFFF' },
  { base: '#37474F', contrast: '#FFFFFF' },
  { base: '#263238', contrast: '#FFFFFF' },
  { base: '#000000', contrast: '#FFFFFF' },
  { base: '#B15D5D', contrast: '#FFFFFF' },
  { base: '#787554', contrast: '#FFFFFF' },
]
