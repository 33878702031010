import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Drop, Sort } from 'components/utilities/drag-drop/DragDrop'
import Block from './Block'
import c from './blocks.module.scss'
import { DragDropData } from 'components/wrappers/drag-drop-wrapper/DragDropWrapper'
import { PlannerSize } from 'components/planner/Planner'
import { Interface } from 'components/wrappers/interface-data-wrapper/InterfaceDataWrapper'

const Blocks = ({
  data,
  filtered,
  periodId,
  fake,
  periodIndex,
  disabled,
  year,
}) => {
  const { selected } = useContext(DragDropData)
  const { plannerSize } = useContext(PlannerSize)
  const {
    setShowDisplayOptions,
    displayOptions: { condenseMode },
  } = useContext(Interface)
  const [columns, setcolumns] = useState(4)
  data = useMemo(() => data, [data])

  // grid size

  useEffect(() => {
    if (plannerSize > 600) setcolumns(4)
    if (plannerSize < 600 && plannerSize > 300) setcolumns(2)
    if (plannerSize < 300) setcolumns(1)
  }, [plannerSize])

  const style = {
    grid: `auto-flow / repeat(${columns}, 1fr)`,
  }

  const hidden = data.length - filtered.length

  // render
  return (
    <Sort
      items={filtered.map((block) => {
        return block ? block.id : false
      })}
      id={periodId}>
      <Drop
        disabled={
          selected?.type !== 'BLOCK' || selected?.type !== 'CREDIT' || disabled
        }
        id={`${periodId}`}
        className={[
          c.blocks,
          condenseMode ? c.condense : '',
          filtered.length === 0 ? c.emptyContainer : '',
        ].join(' ')}
        style={style}
        styleAfter={{ backgroundColor: 'red' }}>
        {filtered.length > 0 ? (
          data.map((block, i) => {
            if (block) {
              return (
                <Block
                  key={block.id}
                  {...{ block, periodIndex, year, fake, disabled }}
                  index={i}
                />
              )
            }

            return null
          })
        ) : (
          <div className={`${c.empty} ${condenseMode ? c.condense : null}`}>
            {hidden === 0 ? (
              'Empty'
            ) : (
              <div
                className={`${c.hidden} ${condenseMode ? c.condense : null}`}>
                {hidden} withdrawn or failed{' '}
                {hidden > 1 || hidden === 0 ? 'units are' : 'unit is'} hidden.{' '}
                <span onClick={() => setShowDisplayOptions(true)}>
                  Change display options
                </span>
                .
              </div>
            )}
          </div>
        )}
      </Drop>
    </Sort>
  )
}

export default Blocks
