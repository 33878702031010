const config = {
  debug: {
    firebase: {
      apiKey: 'AIzaSyCEwjSNkUzufAJhpRgy6EEdh2N2STBS-2U',
      authDomain: 'monash-course-advice-dev.firebaseapp.com',
      projectId: 'monash-course-advice-dev',
      storageBucket: 'monash-course-advice-dev.appspot.com',
      messagingSenderId: '802302084171',
      appId: '1:802302084171:web:38aa8583c7bd7300c579ce',
    },
    proxy: {
      authHost: 'localhost:9099',
      firestoreHost: 'localhost:8080',
      functionsHost: 'localhost:5001',
    },
    oktaLoginUrl: 'http://localhost:9080/sso/authorization_code/login',
    serviceBaseUrl: 'http://localhost:9080',
    tokenApi: 'http://localhost:9080/sso/authorization_code/firebase_custom_token'
  },
  local: {
    firebase: {
      apiKey: 'AIzaSyCEwjSNkUzufAJhpRgy6EEdh2N2STBS-2U',
      authDomain: 'monash-course-advice-dev.firebaseapp.com',
      projectId: 'monash-course-advice-dev',
      storageBucket: 'monash-course-advice-dev.appspot.com',
      messagingSenderId: '802302084171',
      appId: '1:802302084171:web:38aa8583c7bd7300c579ce',
    },
    proxy: {
      authHost: 'course-mapper-dev.m.monash',
      firestoreHost: 'course-mapper-dev.m.monash',
      functionsHost: 'course-mapper-dev.m.monash',
    },
    oktaLoginUrl: 'https://course-mapper-dev.m.monash/sso/authorization_code/login',
    serviceBaseUrl: 'https://course-mapper-dev.m.monash',
    tokenApi: 'https://course-mapper-dev.m.monash/sso/authorization_code/firebase_custom_token',
  },
  dev: {
    firebase: {
      apiKey: 'AIzaSyCEwjSNkUzufAJhpRgy6EEdh2N2STBS-2U',
      authDomain: 'monash-course-advice-dev.firebaseapp.com',
      projectId: 'monash-course-advice-dev',
      storageBucket: 'monash-course-advice-dev.appspot.com',
      messagingSenderId: '802302084171',
      appId: '1:802302084171:web:38aa8583c7bd7300c579ce',
    },
    proxy: {
      authHost: `${window.location.host}`,
      firestoreHost: `${window.location.host}`,
      functionsHost: `${window.location.host}`,
    },
    oktaLoginUrl: `${window.location.origin}/sso/authorization_code/login`,
    serviceBaseUrl: `${window.location.origin}`,
    tokenApi: `${window.location.origin}/sso/authorization_code/firebase_custom_token`,
  },
  test: {
    firebase: {
      apiKey: "AIzaSyD2rjyV-PQHEAvZspoqTn9-lFES6fGsghI",
      authDomain: "monash-course-advice-test.firebaseapp.com",
      projectId: "monash-course-advice-test",
      storageBucket: "monash-course-advice-test.appspot.com",
      messagingSenderId: "874169263635",
      appId: "1:874169263635:web:f47f53c45329c3ba5c81e6"
    },
    proxy: {
      authHost: `${window.location.host}`,
      firestoreHost: `${window.location.host}`,
      functionsHost: `${window.location.host}`,
    },
    oktaLoginUrl: `${window.location.origin}/sso/authorization_code/login`,
    serviceBaseUrl: `${window.location.origin}`,
    tokenApi: `${window.location.origin}/sso/authorization_code/firebase_custom_token`,
  },
  uat: {
    firebase: {
      apiKey: 'AIzaSyCeLyF6_NJ0gt0ZXULpOSKJ4Bxa_cux2NI',
      authDomain: 'monash-course-advice-uat.firebaseapp.com',
      projectId: 'monash-course-advice-uat',
      storageBucket: 'monash-course-advice-uat.appspot.com',
      messagingSenderId: '1066866381153',
      appId: '1:1066866381153:web:6d3228e7966939c4027520',
    },
    proxy: {
      authHost: `${window.location.host}`,
      firestoreHost: `${window.location.host}`,
      functionsHost: `${window.location.host}`,
    },
    oktaLoginUrl: `${window.location.origin}/sso/authorization_code/login`,
    serviceBaseUrl: `${window.location.origin}`,
    tokenApi: `${window.location.origin}/sso/authorization_code/firebase_custom_token`,
  },
  prod: {
    firebase: {
      apiKey: 'AIzaSyBmLr0Ub7SOe7Zj-YeJ9jbKzuuFl9amqkQ',
      authDomain: 'monash-course-advice-prod.firebaseapp.com',
      projectId: 'monash-course-advice-prod',
      storageBucket: 'monash-course-advice-prod.appspot.com',
      messagingSenderId: '271575236564',
      appId: '1:271575236564:web:e1e0f130ba3ee8cf646041',
    },
    proxy: {
      authHost: `${window.location.host}`,
      firestoreHost: `${window.location.host}`,
      functionsHost: `${window.location.host}`,
    },
    oktaLoginUrl: `${window.location.origin}/sso/authorization_code/login`,
    serviceBaseUrl: `${window.location.origin}`,
    tokenApi: `${window.location.origin}/sso/authorization_code/firebase_custom_token`,
  },
}

const getEnvConfig = () => {
  let env = (`${process.env.REACT_APP_ENV}` || 'local').trim().toLowerCase()
  let host = window.location.host.split('.')[0]
  if (host.includes('-dev')) env = 'dev'
  if (host.includes('-test')) env = 'test'
  if (host.includes('-uat') || host === 'course-mapper-qa') env = 'uat'
  if (host.includes('-prod') || host === 'course-mapper') env = 'prod'

  return config[env]
}

const envConfig = getEnvConfig()
const firebaseConfig = envConfig.firebase
const proxyConfig = envConfig.proxy

export default envConfig
export { firebaseConfig, proxyConfig }
