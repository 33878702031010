import React from 'react'
import c from './course-label.module.scss'

const colours = ['#86456C', '#2D6C7A', '#204f88']

const CourseLabel = ({ origin, code, index }) => {
  if (origin !== 'PMAP') {
    return null
  }

  return (
    <div className={c.courseLabel} style={{ color: `${colours[index]}` }}>
      {code}
    </div>
  )
}

export default CourseLabel
