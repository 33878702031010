import React, { useState, useEffect } from 'react'
import c from './handbook.module.scss'
import HandbookSection from './HandbookSection'
import Accordion from 'components/browser/content/modules/accordion/Accordion'
import htmlParse from 'html-react-parser'
import { handbookSearch } from 'utils/services'
import { nanoid } from 'nanoid'
import HandbookBlocks from './HandbookBlocks'

//
// Handbook
//

const Handbook = ({ pageData, callista }) => {
  const oldHandbook = () => {
    let typeName
    let id
    switch (pageData.type) {
      case 'course':
        typeName = 'courses'
        id = `${pageData.code}.html`
        break
      case 'unit':
        typeName = 'units'
        id = `${pageData.code}.html`
        break
      case 'aos':
        typeName = 'aos'
        id = (pageData.title || callista[Object.keys(callista)[0]]?.title || '')
          .replace(/ /g, '-')
          .toLowerCase()
        break
      default:
        typeName = 'courses'
    }
    return `https://www.monash.edu.au/pubs/${pageData.implementationYear}handbooks/${typeName}/${id}`
  }

  const unitWhitelist = pageData?.data
    ? {
        unit_offering: {
          title: 'Offering',
          content: () => {
            return pageData.offerings.length > 0
              ? pageData.offerings.map((o) => (
                  <p>
                    {o.teachingPeriod} {'\u2013'} {o.location} {'\u2013'}{' '}
                    {o.mode}
                  </p>
                ))
              : null
          },
        },
        enrolment_rules: {
          title: 'Enrolment rules',
          content: () => {
            return pageData.data.enrolment_rules.length > 0
              ? htmlParse(pageData.data.enrolment_rules[0].description)
              : null
          },
        },
      }
    : {}

  const unitInformation = () => {
    return Object.keys(unitWhitelist)
      .filter((x) => unitWhitelist[x].content())
      .map((section, i) => {
        if (pageData.data[section]) {
          return (
            <section key={i}>
              <Accordion
                header={<h2>{unitWhitelist[section].title}</h2>}
                expanded={true}>
                {unitWhitelist[section].content()}
              </Accordion>
            </section>
          )
        }

        return null
      })
  }

  const [relatedPages, setRelatedPages] = useState([])
  useEffect(() => {
    //search for possible other versions of the same thing and display as blocks
    if (!pageData?.handbook) {
      //aos only
      handbookSearch({
        target: pageData.code.slice(0, -2),
        size: 7,
        type: pageData.type,
      }).then((r) => {
        const blockified = (r.hits?.hits || [])
          .filter((item) => item['_source'].code !== pageData.code)
          .slice(0, 4)
          .map((item) => {
            const source = item['_source']
            const type = source.data.academic_item_type.toUpperCase()
            return {
              id: nanoid(),
              type: 'AOS',
              //type === "UNIT" ? "BLOCK" : type === "COURSE" ? type : "AOS",
              blockType: type,
              origin: 'BROWSER',
              creditPoints: source.creditPoints || 0,
              name: source.code,
              description: source.title,
              year: source.implementationYear,
            }
          })
        //chop blockified into years
        const chopped = {}
        blockified.forEach((b) => {
          if (chopped[b.year]) {
            chopped[b.year].push(b)
          } else {
            chopped[b.year] = [b]
          }
        })
        setRelatedPages(chopped)
      })
    }
  }, [pageData])

  const overviewWhitelist = {
    course: {
      overview: 'Overview',
    },
    'area of study': {
      handbook_description: 'Overview',
    },
    aos: {
      handbook_description: 'Overview',
    },
    unit: {
      handbook_synopsis: 'Overview',
    },
  }

  const overviewData = () => {
    const type = pageData.type.toLowerCase()
    const key = Object.keys(overviewWhitelist[type])[0]
    const content = pageData.data?.[key] || []

    if (content.length > 2) {
      return (
        <section>
          <Accordion
            header={<h2>{overviewWhitelist[type][key]}</h2>}
            expanded={true}>
            {htmlParse(content)}
          </Accordion>
        </section>
      )
    } else {
      return null
    }
  }

  // render

  return (
    <div className={c.Handbook}>
      {pageData.data && overviewData()}
      <HandbookSection data={pageData?.data?.curriculum?.container} lvl={0} />
      {pageData?.handbook === true &&
        pageData.type.toLowerCase() === 'unit' &&
        unitInformation()}
      {pageData?.handbook === false && (
        <div>
          {pageData.implementationYear < 2020 ? (
            <div className={c.container}>
              <div className={c.section}>
                <div className={c.sectionContents}>
                  <a href={oldHandbook()} target='_blank' rel='noreferrer'>
                    Go to {pageData.implementationYear} handbook (Old)
                  </a>
                </div>
              </div>
            </div>
          ) : (
            <div className={c.container}>
              <div className={c.section}>
                <p className={c.sectionContents}>
                  Could not find {pageData.code} handbook data for{' '}
                  {pageData.implementationYear}
                </p>
              </div>
            </div>
          )}

          {pageData.type === 'aos' && Object.keys(relatedPages).length > 0 && (
            <div className={c.container}>
              <div className={c.section}>
                <Accordion
                  header={<h2>Similar areas of study</h2>}
                  innerStyle={{ padding: '0' }}>
                  {Object.keys(relatedPages)
                    .sort((a, b) => parseInt(a) - parseInt(b))
                    .map((year) => (
                      <div>
                        <span className={c.description}>{year}</span>
                        <HandbookBlocks data={relatedPages[year]} double />
                      </div>
                    ))}
                </Accordion>
              </div>
            </div>
          )}
        </div>
      )}
      {!pageData && (
        <section>
          <h2>Handbook</h2>
        </section>
      )}
    </div>
  )
}

export default Handbook
