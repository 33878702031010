import { DragOverlay } from '@dnd-kit/core'
//import { snapCenterToCursor } from '@dnd-kit/modifiers'
import Block from 'components/planner/mapper/blocks/Block'
import DragDrop from 'components/utilities/drag-drop/DragDrop'
import React, { useState } from 'react'
import { createPortal } from 'react-dom'
import Monitor from './Monitor'

// context
export const DragDropData = React.createContext()

// wrapper
const DragDropWrapper = ({ children }) => {
  const [selected, setSelected] = useState(null) //does this need to be { id: null } ?
  const [selectedProps, setSelectedProps] = useState(null)

  // overlay

  const overlay = () => {
    if (selected) {
      if (selected.type === 'BLOCK' || selected.type === 'AOS') {
        return (
          <Block
            block={selected}
            style={{
              cursor: 'grabbing',
              height: `${selectedProps?.height}px`,
              boxShadow: `3px 3px 12px rgba(0,0,0,0.3)`,
            }}
            fake
          />
        )
      }

      if (selected.type === 'PERIOD') {
        // return <Period period={selected} />
      }
    }
  }

  // render

  return (
    <DragDropData.Provider
      value={{ selected, setSelected, selectedProps, setSelectedProps }}>
      {/* drag drop context */}
      {/* <DragDrop {...{ onDragStart, onDragOver, onDragEnd }}> */}
      <DragDrop>
        <Monitor>{children}</Monitor>

        {createPortal(
          <DragOverlay adjustScale={false}>{overlay()}</DragOverlay>,
          document.body
        )}
      </DragDrop>

      {/* overlay */}
    </DragDropData.Provider>
  )
}

export default DragDropWrapper
