import ContextMenu from 'components/utilities/context-menu/ContextMenu'
import React, { useState } from 'react'
import { createPortal } from 'react-dom'

export const ContextMenuData = React.createContext()

const ContextMenuWrapper = ({ children }) => {
  const [menu, setMenu] = useState({ items: null })

  const close = (e) => {
    e.preventDefault()
    setMenu({ items: null })
  }

  // render

  return (
    <ContextMenuData.Provider value={{ menu, setMenu }}>
      {children}
      {menu.items &&
        createPortal(
          <ContextMenu
            close={close}
            items={menu.items}
            position={menu.position}></ContextMenu>,
          document.body
        )}
    </ContextMenuData.Provider>
  )
}

export default ContextMenuWrapper
