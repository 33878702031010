export const isPass = (grade) => {
  return grades[grade]?.result === 'PASS' || false
}

export const gradeStatus = (grade) => {
  return grades[grade]?.result
}

export const grades = {
  HD: { name: 'HIGH DISTINCTION', result: 'PASS' },
  D: { name: 'DISTINCTION', result: 'PASS' },
  C: { name: 'CREDIT', result: 'PASS' },
  P: { name: 'PASS', result: 'PASS' },
  SFR: { name: 'SATISFIED FACULTY REQUIREMENTS', result: 'PASS' },
  NE: { name: 'NOT EXAMINABLE', result: 'PASS' },
  NAS: { name: 'NOT ASSESSED', result: 'PASS' },
  WH: { name: 'WITHHELD', result: 'INCOMP' },
  DEF: { name: 'DEFERRED', result: 'INCOMP' },
  NS: { name: 'SUPPLEMENTARY GRANTED', result: 'INCOMP' },
  N: { name: 'FAIL', result: 'FAIL' },
  NH: { name: 'HURDLE FAIL', result: 'FAIL' },
  WN: { name: 'WITHDRAWN FAIL', result: 'FAIL' },
  NSR: { name: 'NOT SATISFIED REQUIREMENTS', result: 'FAIL' },
  WDN: { name: 'WITHDRAWN', result: 'WITHDRAWN' },
  WI: { name: 'WITHDRAWN INCOMPLETE', result: 'WITHDRAWN' },
  HI: { name: '1ST CLASS HONOURS', result: 'PASS' },
  HIIA: { name: '2ND CLASS HONOURS DIVISION A', result: 'PASS' },
  HIIB: { name: '2ND CLASS HONOURS DIVISION B', result: 'PASS' },
  HIII: { name: '3RD CLASS HONOURS', result: 'PASS' },
  NP: { name: 'NEAR PASS', result: 'PASS' },
  DISC: { name: 'DISCONTINUED', result: 'WITHDRAWN' },
  PGO: { name: 'PASS GRADE ONLY', result: 'PASS' },
  NGO: { name: 'FAIL', result: 'FAIL' },
  WNGO: { name: 'WITHDRAWN FAIL', result: 'FAIL' },
  ER: { name: 'EXCEEDS REQUIREMENTS', result: 'PASS' },
  MR: { name: 'MEETS REQUIREMENTS', result: 'PASS' },
  DR: { name: 'DOES NOT MEET REQUIREMENTS', result: 'FAIL' },
  MERIT: { name: 'MERIT GRADE', result: 'PASS' },
  FP: { name: 'FACULTY PASS', result: 'PASS' },
  NA: { name: 'NOT APPLICABLE', result: 'PASS' },
  DEFOL: { name: 'DEFERRED - OPEN LEARNING', result: 'WITHDRAWN' },
  DNU: { name: 'DO NOT USE (CENTRAL USE ONLY)', result: 'PASS' },
  H1: { name: 'FIRST CLASS HONOURS', result: 'PASS' },
  H2A: { name: 'SECOND CLASS HONOURS - A', result: 'PASS' },
  H2B: { name: 'SECOND CLASS HONOURS - B', result: 'PASS' },
  H3: { name: 'THIRD CLASS HONOURS', result: 'PASS' },
  PD: { name: 'PASS WITH DISTINCTION', result: 'PASS' },
  CD: { name: 'CONDONED DISTINCTION', result: 'PASS' },
  ACICI: { name: 'ACADEMIC CREDIT - INTERNAL', result: 'PASS' },
  CR: { name: 'ACADEMIC CREDIT - EXTERNAL', result: 'PASS' },
  ECC: { name: 'EXEMPT DUE TO CURRIC CHANGE', result: 'PASS' },
  CP: { name: 'CONDONED PASS', result: 'PASS' },
  F: { name: 'FAIL', result: 'FAIL' },
  SUP: { name: 'QUALIFIES FOR SUPP EXAM', result: 'INCOMP' },
  FES: { name: 'FAILED EXAM SUBMINIMUM, FAIL', result: 'FAIL' },
  AB: { name: 'ABSENT', result: 'FAIL' },
  DNQ: { name: 'DID NOT QUALIFY FOR EXAM ENTRY', result: 'FAIL' },
  A: { name: '100-80', result: 'PASS' },
  B: { name: '79-75', result: 'PASS' },
  E: { name: '64-60', result: 'PASS' },
  G: { name: '49-0', result: 'FAIL' },
  SR: { name: 'SATISFIED REQUIREMENTS', result: 'PASS' },
  NYS: { name: 'REQUIREMENTS NOT YET SATISFIED', result: 'INCOMP' },
}
