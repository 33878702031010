import React, { useEffect, useState } from 'react'

export const Interface = React.createContext()

//display options
const DefaultDisplayOptions = {
  showFailedUnits: false,
  showDescription: true,
  showNotes: true,
  show6CPTags: false,
  lockUnits: true,
  condenseMode: false,
}

const currentDisplayOptions = {
  ...DefaultDisplayOptions,
  ...JSON.parse(localStorage.getItem('displayOptions')),
}

//migrate existing display option for compact mode
if (currentDisplayOptions.hasOwnProperty('compactMode')) {
  currentDisplayOptions.showDescription = !currentDisplayOptions.compactMode
  delete currentDisplayOptions.compactMode
}

//student view display options
const DefaultStudentDisplayOptions = {
  show6CPTags: false,
}

const currentStudentDisplayOptions = {
  ...DefaultStudentDisplayOptions,
  ...JSON.parse(localStorage.getItem('studentDisplayOptions')),
}

const InterfaceDataWrapper = ({ children }) => {
  const [showPlanningPanel, setShowPlanningPanel] = useState(false)
  const [offsetPlanner, setOffsetPlanner] = useState(0)

  // batch add aos
  const [unitsToAdd, setUnitsToAdd] = useState({})
  const [editMode, setEditMode] = useState(null)

  // display options
  const [showDisplayOptions, setShowDisplayOptions] = useState(false)
  const [displayOptions, setDisplayOptions] = useState(currentDisplayOptions)

  // student view display options
  const [studentDisplayOptions, setStudentDisplayOptions] = useState(
    currentStudentDisplayOptions
  )

  useEffect(() => {
    localStorage.setItem(
      'studentDisplayOptions',
      JSON.stringify(studentDisplayOptions)
    )
  }, [studentDisplayOptions])

  useEffect(() => {
    localStorage.setItem('displayOptions', JSON.stringify(displayOptions))
  }, [displayOptions])

  // render

  return (
    <Interface.Provider
      value={{
        showPlanningPanel,
        setShowPlanningPanel,
        offsetPlanner,
        setOffsetPlanner,
        unitsToAdd,
        setUnitsToAdd,
        editMode,
        setEditMode,
        showDisplayOptions,
        setShowDisplayOptions,
        displayOptions,
        setDisplayOptions,
        studentDisplayOptions,
        setStudentDisplayOptions,
      }}>
      {children}
    </Interface.Provider>
  )
}

export default InterfaceDataWrapper
