import React, { useContext } from 'react'
import Icon from 'components/utilities/icons/Icon'
import stringToColor from 'utils/colors/string-to-color'
import { ContextMenuData } from 'components/wrappers/context-menu-wrapper/ContextMenuWrapper'
import c from './aos-tag.module.scss'
import { RouterState } from 'utils/Router'
import { Interface } from 'components/wrappers/interface-data-wrapper/InterfaceDataWrapper'

const AosTag = ({
  aos,
  count,
  warning,
  noClick = false,
  children,
  bulkEdit,
  remove,
}) => {
  const { setMenu } = useContext(ContextMenuData)
  const { redirect } = useContext(RouterState)
  const { editMode } = useContext(Interface)
  const { contrast, base } = stringToColor(aos.code ? aos.code.slice(0, -2) : 'aos')
  const {
    displayOptions: { condenseMode },
  } = useContext(Interface)

  const contextMenu = (e) => {
    e.preventDefault()
    setMenu({
      id: aos.code,
      position: { x: e.clientX, y: e.clientY },
      items: [
        {
          name: `View ${aos.code}`,
          icon: <Icon.Info />,
          fn: () =>
            redirect(
              // `browse/aos/${aos.code}${
              //     aos.start && `?year=${aos.start}`
              // }`
              `browse/aos/${aos.code}`
            ),
        },
        !editMode && {
          name: `Bulk edit ${aos.code}`,
          icon: <Icon.Pencil />,
          fn: () => bulkEdit(aos.code),
        },
        { name: 'BREAK' },
        !editMode && {
          name: `Remove ${aos.code}`,
          icon: <Icon.Trash />,
          className: c.red,
          fn: () => remove(),
        },
      ],
    })
  }

  return (
    <li
      className={`${editMode && editMode !== aos.code ? c.inActive : null} ${
        condenseMode ? c.condense : null
      } ${c.tag}`}
      style={{ color: contrast, backgroundColor: base }}
      onContextMenu={!noClick && contextMenu}>
      <span>
        {(count || count === 0) && <span className={c.count}>{count}</span>}
        <span> {children}</span>
      </span>
      {warning && (
        <span className={c.warning}>
          {'\u2013'} {aos.start}
          <Icon.Alert />
        </span>
      )}
    </li>
  )
}

export default AosTag
