import config from './config'
import { auth } from './firebase'

const endpointPrefix = config.serviceBaseUrl

const getFetchOptions = async (method, noauth) => {
  // Default options are marked with *
  const options = {
    method: `${method}`, // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  }
  if (auth.currentUser) {
    try {
      if (!noauth) {
        const firebaseIDToken = await auth.currentUser.getIdToken(
          /* forceRefresh */ true
        )
        options.headers["Authorization"] = `Bearer ${firebaseIDToken}`
      }
      return options
    } catch (error) {
      throw new APICallError(
        'API cannot be called since getting Firebase ID token failed.',
        error,
        options
      )
    }
  } else {
    throw new APICallError(
      'API cannnot be called without a Firebase user logged in.',
      {},
      options
    )
  }
}

class APICallError extends Error {
  constructor(message, original, data) {
    super(message)
    this.original = original
    this.data = data
  }
}

const call = async (url, options) => {
  let responseText = ''
  let status = -1
  try {
    const response = await fetch(url, options)
    responseText = await response.text()
    status = response.status
    // console.log(response)
    const responseObj = JSON.parse(responseText)
    return responseObj
  } catch (error) {
    const callData = {
      data: responseText,
      status: status,
      url: url,
      fetchOptions: options,
    }
    console.log(error, url)
    console.log('[API] API call failed: ', callData)
    throw new APICallError('API call failed.', error, callData)
  }
}

export { getFetchOptions, call, endpointPrefix, APICallError }