import React, { useState, useContext, useEffect } from 'react'
import Error from 'components/utilities/error/Error'
import Modal from 'components/utilities/modal/Modal'
import { AuthContext } from '@monash/portal-auth'

export const ErrorData = React.createContext()

const ErrorWrapper = ({ children }) => {
  const [error, setError] = useState({type: null, popup: false})
  const authCtx = useContext(AuthContext)

  useEffect(() => {
    if (authCtx.error) {
      setError({...authCtx.error, popup: false})
    }
  }, [authCtx])

  // render

  return (
    <ErrorData.Provider value={{ error, setError }}>
      {error.type && error.popup && (
        <Modal isShown={true} content={<Error />}/>
      )}
      {error.type && !error.popup && (
          <Error />
      )}
      {!error.type && children}
    </ErrorData.Provider>
  )
}

export default ErrorWrapper
