import Icon from 'components/utilities/icons/Icon'
import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import c from './modal.module.scss'

const Modal = ({
  isShown,
  content,
  heading,
  toggle,
  action,
  actionMessage,
  slim = false,
}) => {
  const [delayedIsShown, setDelayedIsShown] = useState(false)
  const timeoutID = useRef(0)

  useEffect(() => {
    if (timeoutID.current) {
      clearTimeout(timeoutID.current)
    }
    timeoutID.current = setTimeout(
      () => {
        setDelayedIsShown(isShown)
      },
      isShown ? 0 : 500
    )
  }, [isShown])

  return (
    delayedIsShown &&
    ReactDOM.createPortal(
      <div className={c.modal}>
        <div
          className={`${c.card} ${isShown ? '' : c.hide} ${slim ? c.slim : ''}`}
        >
          <div className={c.head}>
            <h2 className={c.title}>{heading || ''}</h2>
            {toggle && (
              <div className={c.closeButton} onClick={toggle}>
                <Icon.X />
              </div>
            )}
          </div>
          <div className={c.content}>{content}</div>
          {(action || actionMessage) && (
            <div className={c.action}>
              <button
                className={c.primaryButton}
                onClick={action || toggle}
              >
                {actionMessage || 'Confirm'}
              </button>
            </div>
          )}
        </div>
        <div className={c.backDrop}></div>
      </div>,
      document.body
    )
  )
}

export default Modal
