import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useContext,
} from 'react'
import TutorialModal from './tutorial-modal/TutorialModal'
import { TutorialContext } from './TutorialProvider'
import c from './tutorial.module.scss'

const Tutorial = ({ onClose }) => {
  const [startHiding, setStartHiding] = useState(false)
  const [modalProps, setModalProps] = useState(null)
  const [highlightedArea, setHighlightedArea] = useState(null)
  const [activeStep, setActiveStep] = useState('Navigation bar')
  const modalRef = useRef()

  const {
    tutorialValue: { refs },
  } = useContext(TutorialContext)

  const handleOnTutorialClose = () => {
    setStartHiding(true)
    setTimeout(() => onClose(), 500)
  }

  const updateHighlightAreaStyle = useCallback(() => {
    if (!refs[activeStep]) {
      return
    }
    setHighlightedArea({
      left: refs[activeStep].offsetLeft,
      top: refs[activeStep].offsetTop,
      width: refs[activeStep].offsetWidth,
      height: refs[activeStep].offsetHeight,
    })
  }, [activeStep, refs])

  const updateModalPosition = useCallback(() => {
    if (!refs[activeStep]) {
      return
    }
    // centered align
    let positionY =
      refs[activeStep].offsetTop +
      refs[activeStep].offsetHeight / 2 -
      modalRef.current.offsetHeight / 2
    let positionX

    //if above viewport
    if (positionY < 0) {
      positionY = 20
      //if below viewport
    } else if (positionY + modalRef.current.offsetHeight > window.innerHeight) {
      positionY = window.innerHeight - modalRef.current.offsetHeight - 20
    }

    if (
      //if component on left
      refs[activeStep].offsetLeft + refs[activeStep].offsetWidth / 2 <
      window.innerWidth / 2
    ) {
      //modal on right
      positionX =
        refs[activeStep].offsetLeft + refs[activeStep].offsetWidth + 20
    } else {
      //else modal on left
      positionX =
        refs[activeStep].offsetLeft - 20 - modalRef.current.offsetWidth
    }
    setModalProps({
      left: positionX,
      top: positionY,
    })
  }, [activeStep, refs])

  const updateTutorialStyle = useCallback(() => {
    updateHighlightAreaStyle()
    updateModalPosition()
  }, [updateHighlightAreaStyle, updateModalPosition])

  const handleResize = useCallback(() => {
    updateTutorialStyle()
  }, [updateTutorialStyle])

  const handleModalChange = (name) => {
    setActiveStep(name)
  }

  useEffect(() => {
    updateTutorialStyle()
  }, [activeStep, updateTutorialStyle])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [handleResize, activeStep])

  return (
    <div className={c.tutorial}>
      <div
        className={`${c.tutorialModal} ${startHiding ? c.hide : ''}`}
        style={
          modalProps
            ? {
                top: `${modalProps.top}px`,
                left: `${modalProps.left}px`,
              }
            : {}
        }>
        <div className={c.pointer}></div>
        <TutorialModal
          onClose={handleOnTutorialClose}
          onChange={handleModalChange}
          ref={modalRef}
        />
      </div>
      <div
        className={c.highlightedArea}
        style={
          highlightedArea
            ? {
                top: `${highlightedArea.top}px`,
                left: `${highlightedArea.left}px`,
                width: `${highlightedArea.width}px`,
                height: `${highlightedArea.height}px`,
              }
            : {}
        }></div>
    </div>
  )
}

export default Tutorial
