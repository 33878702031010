import React from 'react'
import c from './view-plan.module.scss'
import VPeriod from './VPeriod'

const VYear = ({ year, showFailed }) => {
  // render

  return (
    <div className={c.year}>
      <h3>{year.name}</h3>
      {year.periods.map((period, i) => (
        <VPeriod period={period} key={i} showFailed={showFailed} />
      ))}
    </div>
  )
}

export default VYear
