import React from 'react'
import c from './loading-indicator.module.scss'

const LoadingIndicator = ({ size = 20, color = 'currentColor' }) => {
  const cubes = new Array(9)
  cubes.fill('')

  // render

  return (
    <div
      className={c.loadingIndicator}
      style={{
        width: `${size - (size % 3)}px`,
        height: `${size - (size % 3)}px`,
      }}>
      {cubes.map((item, i) => (
        <div
          className={c.cube}
          key={i}
          style={{ backgroundColor: color }}></div>
      ))}
    </div>
  )
}

export default LoadingIndicator
