import React from 'react'
import s from './intro-help-and-support.module.scss'

const IntroHelpAndSupport = () => {
  return (
    <div className={s.containerMain}>
      <div className={s.overlayChatBoxLeft}>
        <div className={s.lines}></div>
        <div className={s.pointyBottom}></div>
      </div>
      <div className={s.chatBoxRight}>
        <div className={s.lines}></div>
        <div className={s.pointyBottom}></div>
      </div>
    </div>
  )
}

export default IntroHelpAndSupport
