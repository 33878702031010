import React, { useState, useContext } from 'react'
import s from './studentSearch.module.scss'
import { CurrentStudent } from '../../wrappers/student-data-wrapper/StudentDataWrapper'
import Icon from 'components/utilities/icons/Icon.jsx'

const StudentSearch = () => {
  const { findStudent, notFound, setNotFound } = useContext(CurrentStudent)
  const [target, setTarget] = useState('')
  const [ifLoading, setIfLoding] = useState(false)

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && target) {
      setIfLoding(true)
      findStudent(target.trim(), '/plans')
    }
  }

  return (
    <span onKeyPress={handleKeyPress}>
      <fieldset
        className={
          notFound
            ? [s.container, s.error].join(' ')
            : ifLoading
            ? [s.container, s.loading].join(' ')
            : s.container
        }>
        <div className={s.search}>
          <input
            id='enter-student-id'
            className={s.search}
            placeholder={'Enter Student ID'}
            value={target}
            onChange={(e) => {
              setTarget(e.target.value.trim())
              setIfLoding(false)
              setNotFound(false)
            }}
          />
        </div>

        <span className={s.icon}>
          <Icon.Enter />
        </span>
      </fieldset>
    </span>
  )
}

export default StudentSearch
