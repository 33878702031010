import Icon from 'components/utilities/icons/Icon'
import Transition from 'components/utilities/transition/Transition'
import React, { useState, useContext } from 'react'
import c from './profile-menu.module.scss'
import { UserData } from 'components/wrappers/user-data-wrapper/UserDataWrapper'
import { AuthContext } from '@monash/portal-auth'
import { RouterState } from 'utils/Router'

const ProfileMenu = () => {
  const { redirect } = useContext(RouterState)
  const { user } = useContext(UserData)
  const authCtx = useContext(AuthContext)

  const [showProfileMenu, setShowProfileMenu] = useState(false)

  const logout = () => {
    sessionStorage.setItem('currentStudent', null)
    authCtx.logout('https://monash.edu')
    console.log('bye')
  }

  const items = [
    {
      name: 'User Guide',
      icon: <Icon.Help />,
      external: true,
      fn: () => {
        window.open('https://user-guide.apps.monash.edu/')
        setShowProfileMenu(false)
      },
    },
    {
      name: 'Feedback',
      icon: <Icon.MailBox />,
      fn: () => {
        redirect('/feedback')
        setShowProfileMenu(false)
      },
    },
    {
      name: 'Glossary',
      icon: <Icon.Book />,
      fn: () => {
        redirect('/glossary')
        setShowProfileMenu(false)
      },
    },
    {
      name: 'Log out',
      icon: <Icon.Logout />,
      fn: () => logout(),
    },
  ]

  const initials = () => {
    if (user) {
      let names = user.displayName?.split(' ')
      return (
        names[0][0].toUpperCase() + names[names.length - 1][0].toUpperCase()
      )
    } else {
      return ''
    }
  }

  // render

  return (
    <div className={c.proileMenu}>
      <button
        className={c.profileMenuButton}
        onClick={(e) => setShowProfileMenu(!showProfileMenu)}>
        {initials()}
      </button>

      <Transition
        show={showProfileMenu}
        enterClass={c.enter}
        exitClass={c.exit}>
        <menu className={c.menu}>
          <div className={c.menuHeader}>
            <div className={c.initials}>{initials()}</div>
            <div className={c.meta}>
              <div className={c.name}>{user?.displayName}</div>
              <div className={c.email}>{user?.email}</div>
            </div>
          </div>
          {items.map((item, i) => (
            <div className={c.item} key={i} onClick={item.fn}>
              <div className={c.itemLabel}>
                {item.icon} {item.name}
              </div>
              {item.external ? <Icon.External /> : null}
            </div>
          ))}
        </menu>
      </Transition>
    </div>
  )
}

export default ProfileMenu
