import React, { useState, useContext } from 'react'
import c from './feedback.module.scss'
import StarRating from './starRating/StarRating'
import { fs } from 'utils/firebase'
import { UserData } from 'components/wrappers/user-data-wrapper/UserDataWrapper'
import Illustration from 'components/utilities/icons/Illustration'
const Feedback = () => {
  // Info
  const [selected, setSelected] = useState('')
  const [rating, setRating] = useState(0)
  const [feedbackComment, setFeedbackComment] = useState('')
  const [bugComment, setBugComment] = useState('')
  const { user } = useContext(UserData)

  //Feedback Form
  const feedbackForm = (
    <div className={c.feedbackForm}>
      <h2>I would rate my experience as</h2>
      <StarRating rating={0} getRating={setRating} />
      <textarea
        required
        className={c.textBox}
        rows='4'
        value={feedbackComment}
        onChange={(e) => setFeedbackComment(e.target.value)}
        placeholder='Share your experience with us and let us know how we can improve (optional).'></textarea>
    </div>
  )

  //Bug Form
  const bugForm = (
    <div className={c.bugForm}>
      <h2>The bug is about</h2>
      <textarea
        required
        className={c.textBox}
        rows='4'
        value={bugComment}
        onChange={(e) => setBugComment(e.target.value)}
        placeholder='What happened? Sharing the details to reproduce the problem you saw can be helpful.'></textarea>
    </div>
  )
  const [ifSubmit, setIfSubmit] = useState(false)
  //Final Outcome
  const getOutcome = (e) => {
    e.preventDefault()
    let feedback = {
      reporter: {
        id: user.id,
        student: user.student,
        staff: user.staff,
      },
      reporterName: user.displayName,
      reportTime: Date(),
      feedbackType: selected,
      feedback: {
        rating,
        feedbackComment,
      },
      bug: bugComment,
    }

    //send to fs
    fs.collection('feedback').add(feedback)

    //reset the form
    setIfSubmit(true)
    setFeedbackComment('')
    setBugComment('')
  }

  // render
  return (
    <div className={c.feedback}>
      {ifSubmit ? (
        <section className={c.submitted}>
          <div>
            <Illustration.Check />
          </div>
          <div>
            Thank you for having taken your time to provide us with your
            valuable feedback. We might contact you for futher clarifition.
          </div>
          <div>
            <button
              className={c.primaryButton}
              onClick={() => {
                setIfSubmit(false)
              }}>
              Submit another feedback
            </button>
          </div>
        </section>
      ) : (
        <section>
          <div className={c.feedbackTitle}>
            <h1>Submit Feedback</h1>
            <span>
              We’re always working to improve the Course Map experience, so we’d
              love to hear what’s working and how we can do better.
            </span>
          </div>
          <div className={c.feedbackContent}>
            <form onSubmit={(e) => getOutcome(e)}>
              <div className={c.feedbackType}>
                <h2>I would like to</h2>
                <div className={c.radioGroup}>
                  <label>
                    <input
                      type='radio'
                      value='feedback'
                      name='feedback'
                      checked={selected === 'feedback'}
                      onChange={() => setSelected('feedback')}></input>
                    Give feedback
                  </label>
                  <label>
                    <input
                      type='radio'
                      value='bug'
                      name='bug'
                      checked={selected === 'bug'}
                      onChange={() => setSelected('bug')}
                    />
                    Report a bug
                  </label>
                </div>
              </div>
              {selected
                ? selected === 'feedback'
                  ? feedbackForm
                  : bugForm
                : null}
              {selected ? (
                <div className={c.submit}>
                  <button className={c.primaryButton}>Submit</button>
                </div>
              ) : null}
            </form>
          </div>
        </section>
      )}
    </div>
  )
}

export default Feedback
