import React, { useState, useContext, useEffect } from 'react'
import c from './search-results.module.scss'
import { RouterState, Link } from 'utils/Router'
import { makeSubstringBold } from 'utils/StringUtil'
import Tabs from 'components/utilities/tabs/Tabs'
import Icon from 'components/utilities/icons/Icon'
import Modal from 'components/utilities/modal/Modal'
import Filters from './Filters'
import { handbookSearch } from 'utils/services'
import LoadingScreen from 'components/utilities/loading-indicator/LoadingScreen'

const SearchResults = () => {
  const { route } = useContext(RouterState)
  const [matches, setMatches] = useState(route.data?.matches || [])
  const [target, setTarget] = useState(route.data?.target || '')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (route?.data) {
      setMatches(route.data.matches || [])
      setTarget(route.data.target || '')
      setLoading(false)
    } else {
      let urlTarget = new URLSearchParams(window.location.search).get('q')
      if (urlTarget) {
        setTarget(urlTarget)
        // setLoading(true)
        handbookSearch({ target: urlTarget, size: 50 }).then((r) => {
          setMatches(r.hits?.hits || [])
          setLoading(false)
        })
      }
    }
  }, [route])

  const highlight = (desc) => {
    const chopped = desc.split('.')
    const first = chopped.findIndex((x) =>
      x.toLowerCase().includes(target?.toLowerCase())
    )
    return first >= 0 ? makeSubstringBold(target, chopped[first] || '') : null //chopped[0]
  }

  const displayResult = (data, i) => {
    const type = data.type === 'Area of Study' ? 'aos' : data.type.toLowerCase()
    return (
      <Link
        to={`/browse/${type}/${data.code}?year=${data.implementationYear}`}
        data={{ type: type, code: data.code, year: data.implementationYear }}
        className={c.card}
        key={i}>
        <div className={c.metaTop}>
          <span className={c.type}>
            {type === 'aos'
              ? data.category.toUpperCase()
              : data.type.toUpperCase()}
          </span>{' '}
          &bull; <span className={c.cp}>{data.code}</span> &bull;{' '}
          <span className={c.cp}>{data.creditPoints || 0}CP</span> &bull;{' '}
          <span className={c.cp}>{data.implementationYear}</span>
        </div>
        <div>
          <strong>{data.title}</strong>
        </div>
        <div>
          <span>{data.description && highlight(data.description)}</span>
        </div>
      </Link>
    )
  }

  const [activeTab, setActiveTab] = useState(0)
  const tabs = [
    { name: 'All', type: 'all' },
    { name: 'Courses', type: 'course' },
    { name: 'Area of Study', type: 'area_of_study' },
    { name: 'Units', type: 'unit' },
  ]

  const [filters, setFilters] = useState([])
  const [showFilterMenu, setShowFilterMenu] = useState(false)

  const applyFilters = (match) => {
    const offerings = ['location', 'teachingPeriod', 'mode']
    return filters.some((f) => {
      if (offerings.includes(f.field)) {
        return f.values.some((v) => {
          return match['_source'].offerings.some((o) => {
            return o[f.field] === v
          })
        })
      } else {
        return f.values.includes(match['_source'][f.field])
      }
    })
  }

  // render

  return (
    <LoadingScreen loaded={!loading} message={`Searching for "${target}"`}>
      <div className={c.searchResults}>
        <p className={c.topText}>Search results for "{target}"</p>

        {matches.length ? (
          <div>
            <div>
              <Tabs
                onChange={(i) => {
                  setActiveTab(i)
                  setFilters([])
                }}
                tabs={tabs}
                activeKey={activeTab}
              />
            </div>
            <div className={c.filters}>
              {filters.map((f, i) => {
                return f.values.map((v, j) => (
                  <span className={c.filter} key={`${i}${j}`}>
                    {v}
                  </span>
                ))
              })}
              <span
                className={c.filterButton}
                onClick={() => setShowFilterMenu(true)}>
                <Icon.Adjust />
                Filters
              </span>
            </div>
            <div className={c.matches}>
              {tabs[activeTab].type === 'all'
                ? matches
                    .filter((m) => applyFilters(m) || filters.length === 0)
                    .map((m, i) => displayResult(m['_source'], i))
                : matches
                    .filter((m) => m['_index'] === tabs[activeTab].type)
                    .filter((m) => applyFilters(m) || filters.length === 0)
                    .map((m, i) => displayResult(m['_source'], i))}
            </div>
          </div>
        ) : (
          <p>
            We couldn’t find anything matching your search. Try searching
            something else.
          </p>
        )}
        <Modal
          isShown={showFilterMenu}
          toggle={() => setShowFilterMenu(false)}
          content={
            <Filters
              type={tabs[activeTab].type}
              selected={filters}
              setFilters={setFilters}
            />
          }
        />
      </div>
    </LoadingScreen>
  )
}

export default SearchResults
