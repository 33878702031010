import React, { useEffect, useRef, useState } from 'react'
import c from './context-menu.module.scss'

// menu items

const MenuItems = ({ list, rect }) => {
  return list.map((item, i) => {
    return item.name === 'BREAK' ? (
      <div key={i} className={c.break} />
    ) : (
      <div
        key={i}
        onClick={item.fn || null}
        className={`${!item.fn && !item.list ? c.disabledItem : ''} ${c.item}`}>
        <div className={item.className}>
          {item.icon || null}
          <span>{item.name}</span>
        </div>
        {item.list && <SubMenu list={item.list} rect={rect} />}
      </div>
    )
  })
}

// sub menu

const SubMenu = ({ rect, name, list }) => {
  const [posX, setPosX] = useState(0)
  const [posY, setPosY] = useState(0)

  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  const ref0 = useRef(null)
  const ref = useRef(null)

  useEffect(() => {
    const w = ref.current.offsetWidth
    const h = ref.current.offsetHeight

    setWidth(w)
    setHeight(h)

    setPosX(rect.w)

    if (rect.x + rect.w + w > window.innerWidth) setPosX(-w)

    if (rect.y + ref0.current.offsetTop + h > window.innerHeight)
      setPosY(ref0.current.offsetTop - 26)
  }, [ref, rect])

  return (
    <div className={c.SubMenu} ref={ref0}>
      <menu style={{ top: `${posY}px`, left: posX + 'px' }} ref={ref}>
        <MenuItems
          list={list}
          rect={{ x: posX, y: posY, w: width, h: height }}
        />
      </menu>
    </div>
  )
}

// actual menu

const Menu = ({ list, rect }) => {
  return <MenuItems {...{ list, rect }} />
}

// context menu

const ContextMenu = ({ position, items, close = null }) => {
  const ref = useRef(null)

  const [posX, setPosX] = useState(position.x)
  const [posY, setPosY] = useState(position.y)
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  useEffect(() => {
    const setPos = () => {
      const w = ref.current.offsetWidth
      const h = ref.current.offsetHeight

      setWidth(w)
      setHeight(h)

      if (position.x + w > window.innerWidth)
        setPosX(window.innerWidth - w - 10)

      if (position.y + h > window.innerHeight)
        setPosY(window.innerHeight - h - 20)
    }

    setPos()
  }, [position, ref])

  // render

  return (
    <div className={c.ContextMenu} onClick={close} onContextMenu={close}>
      <menu style={{ left: posX, top: posY }} ref={ref}>
        <Menu
          list={items.filter((item) => item)}
          rect={{ x: posX, y: posY, w: width, h: height }}
        />
      </menu>
    </div>
  )
}

export default ContextMenu
