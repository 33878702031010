import React from 'react'
import s from './intro-unit-selection.module.scss'

const IntroUnitSelection = () => {
  return (
    <div className={s.containerMain}>
      <div className={s.unitTop}></div>
      <div className={s.unitBottomLeft}></div>
      <div className={s.unitBottomRight}></div>
    </div>
  )
}

export default IntroUnitSelection
