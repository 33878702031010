import React from 'react'
import c from './tabs.module.scss'

const Tab = ({ label, active, onClick }) => {
  return (
    <li onClick={onClick} className={`${active ? c.active : ''}`}>
      {label}
    </li>
  )
}

export default Tab
