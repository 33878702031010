import React from 'react'
import s from './intro-search-and-browse.module.scss'
import Icon from 'components/utilities/icons/Icon'

const IntroSearchAndBrowse = () => {
  return (
    <div className={s.containerMain}>
      <div className={s.typeField}>
        <div className={s.lines}></div>
      </div>
      <div className={s.bottomDots}></div>
      <div className={s.search}>
        <Icon.Search />
      </div>
    </div>
  )
}

export default IntroSearchAndBrowse
