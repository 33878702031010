import Icon from 'components/utilities/icons/Icon'
import React from 'react'
import s from './intro-create-course-plan.module.scss'

const IntroCreateCoursePlan = () => {
  return (
    <div className={s.containerMain}>
      <div className={s.bottomDots}></div>
      <div className={s.iconPlus}>
        <Icon.Plus />
      </div>
      <div className={s.overlayCard}></div>
      <div className={s.bottomCard}></div>
    </div>
  )
}

export default IntroCreateCoursePlan
