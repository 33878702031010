const flattenCourseMap = (courseMap) => {
  return courseMap
    ? Object.values(courseMap.years)
        .map((year) => year.periods?.map((period) => period.blocks))
        .flat(2)
        .concat(Object.values(courseMap.advancedStandings).flat(2))
    : []
}

export default flattenCourseMap
