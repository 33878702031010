import { isPass } from 'utils/data/grades'
//const goodEggs = ['SFR', 'NE', 'NAS']

// value: the value to check against
// type: the type of value - eg: name, id, etc
// map: the course map with years/periods/blocks

const checkMap = (value, type, map) => {
  let onMap = map?.filter((item) => {
    return item[type] === value
  })
  .sort((a, b) => {
    let aMark = parseInt(a.mark || (isPass(a.grade) ? 50 : 0) || 50)
    let bMark = parseInt(b.mark || (isPass(b.grade) ? 50 : 0) || 50)
    return aMark > bMark ? 1 : aMark < bMark ? -1 : 0
  })
  return onMap[onMap.length - 1]
}

export default checkMap
