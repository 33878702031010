import React from 'react'
import c from './tutorial-graphics.module.scss'

const TutorialInformation = () => {
  return (
    <div className={c.containerMain}>
      <div className={c.panel}>1</div>
      <div className={`${c.panel} ${c.active}`}>2</div>
      <div className={c.panel}>3</div>
    </div>
  )
}

export default TutorialInformation
