import { colorsList } from './colors-list'

const stringToColor = function(seed) {
  let hash = 0

  if (seed.length === 0) return hash

  for (let i = 0; i < seed.length; i++) {
    hash = seed.charCodeAt(i) + ((hash << 5) - hash)
    hash |= 0
  }

  let index = Math.abs(hash) % colorsList.length

  return {
    base: colorsList[index].base,
    contrast: colorsList[index].contrast
  }
}

export default stringToColor
