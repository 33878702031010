import Icon from 'components/utilities/icons/Icon'
import Toggle from 'components/utilities/toggle/Toggle'
import { Data } from 'components/wrappers/course-map-data-wrapper/CourseMapDataWrapper'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { RouterState } from 'utils/Router'
import HoverMenu from '../mapper/hover-menu/HoverMenu'
import c from './change-summary.module.scss'
import ChangeSummaryComment from './ChangeSummaryComment'

const ChangeSummary = ({ setEmptyChangeSummary }) => {
  const { courseMap, setCourseMap, autosave } = useContext(Data)
  const { route } = useContext(RouterState)

  // view only mode

  const [viewOnly, setViewOnly] = useState(true)

  useEffect(() => {
    setViewOnly(/^\/view/gi.test(route.path))
  }, [route])

  // hide from student view

  const [hideFromStudentView, setHideFromStudentView] = useState(
    courseMap.hideFromStudentView
  )

  useEffect(() => {
    if (hideFromStudentView !== courseMap.hideFromStudentView) {
      setCourseMap((f) => {
        const newMap = { ...f }
        newMap.hideFromStudentView = hideFromStudentView
        autosave(newMap)
        return newMap
      })
    }
  }, [
    autosave,
    courseMap.hideFromStudentView,
    hideFromStudentView,
    setCourseMap,
  ])

  // original

  const [original, setOriginal] = useState(courseMap.original)

  useEffect(() => {
    if (original?.id !== courseMap?.original?.id) {
      setOriginal(courseMap.original)
    }
  }, [courseMap?.original, original?.id])

  // comments

  const addComment = (type) => {
    setCourseMap((f) => {
      const newMap = { ...f }
      if (!newMap.changeSummary)
        newMap.changeSummary = { comments: { unit: [], aos: [] } }
      newMap.changeSummary.comments[type].push({
        content: 'Comment',
        status: 'EDIT',
        type,
      })
      autosave(newMap)
      return newMap
    })
  }

  // changes

  const [changes, setChanges] = useState({
    aos: { additions: [], subtractions: [] },
    units: { additions: [], subtractions: [] },
  })

  // aos changes

  const getAoSChanges = useCallback(() => {
    const additions = []
    const subtractions = []

    const check = (a, b, array) => {
      a.aos.forEach((item) => {
        if (!b.aos?.some((aos) => aos.code === item.code)) array.push(item)
      })
    }

    check(original, courseMap, subtractions)
    check(courseMap, original, additions)

    setChanges((f) => {
      return { ...f, aos: { additions, subtractions } }
    })
  }, [courseMap, original])

  // unit changes

  const getUnitChanges = useCallback(() => {
    const additions = []
    const subtractions = []

    const check = (a, b, array) => {
      Object.keys(a.years).forEach((y) => {
        a.years[y].periods?.forEach((p) =>
          p.blocks.forEach((u) => {
            if (u?.blockType === 'UNIT') {
              if (
                !b.years[y] ||
                !b.years[y]?.periods.some((item) => item?.name === p?.name) ||
                !b.years[y]?.periods
                  .filter((item) => item.name === p.name)
                  .filter((fp) =>
                    fp.blocks.some((item) => item?.name === u?.name)
                  ).length
              )
                array.push({ year: y, period: p.name, unit: u })
            }
          })
        )
      })
    }

    check(courseMap, courseMap.original, additions)
    check(courseMap.original, courseMap, subtractions)

    setChanges((f) => {
      return { ...f, units: { additions, subtractions } }
    })
  }, [courseMap])

  // check changes

  useEffect(() => {
    if (original) {
      if (original.aos) getAoSChanges()
      getUnitChanges()
    }
  }, [courseMap, getAoSChanges, getUnitChanges, original])

  useEffect(() => {
    if (setEmptyChangeSummary)
      setEmptyChangeSummary(
        !changes.aos.additions.length &&
          !changes.aos.subtractions.length &&
          !changes.units.additions.length &&
          !changes.units.subtractions.length
          ? true
          : false
      )
  }, [changes, setEmptyChangeSummary])

  // render unit changes

  const renderUnitChanges = () => {
    const renderObject = {}

    const checkThenAdd = (item, type) => {
      if (!renderObject[item.year]) renderObject[item.year] = {}
      if (!renderObject[item.year][item.period])
        renderObject[item.year][item.period] = {
          additions: [],
          subtractions: [],
        }

      renderObject[item.year][item.period][type].push(
        `${item.unit.name} ${item.unit.description}`
      )
    }

    changes.units.subtractions.forEach((item) =>
      checkThenAdd(item, 'subtractions')
    )
    changes.units.additions.forEach((item) => checkThenAdd(item, 'additions'))

    return Object.keys(renderObject).map((y, i) => (
      <div className={c.year} key={i}>
        <h4>{y}</h4>
        {Object.keys(renderObject[y]).map((p, j) => (
          <div className={c.teachingPeriod} key={j}>
            <h5>{p}</h5>
            {renderObject[y][p].subtractions.length ? (
              <ul className={c.subractedUnits}>
                {renderObject[y][p].subtractions.map((u, k) => (
                  <li key={k}>
                    <div>
                      <Icon.Minus />
                      <span>{u}</span>
                    </div>
                  </li>
                ))}
              </ul>
            ) : null}

            {renderObject[y][p].additions.length ? (
              <ul className={c.addedUnits}>
                {renderObject[y][p].additions.map((u, k) => (
                  <li key={k}>
                    <div>
                      <Icon.Plus />
                      <span>{u}</span>
                    </div>
                  </li>
                ))}
              </ul>
            ) : null}
          </div>
        ))}
      </div>
    ))
  }

  // render

  return (
    <div
      className={[c.ChangeSummary, viewOnly ? c.viewOnly : ''].join(' ')}
      id='change-summary'>
      {!viewOnly && (
        <h2>
          <span className={c.title}>Plan change summary</span>
          <span className={c.hideFromStudentView}>
            <span>Hide from student view</span>
            <Toggle
              onClick={() => setHideFromStudentView(!hideFromStudentView)}
              on={hideFromStudentView}
            />
          </span>
        </h2>
      )}
      {changes.aos.additions.length || changes.aos.subtractions.length ? (
        <>
          {viewOnly ? (
            <h3>Areas of study</h3>
          ) : (
            <HoverMenu title={<h3>Areas of study</h3>}>
              <button onClick={() => addComment('aos')}>
                <Icon.Comment />
              </button>
            </HoverMenu>
          )}
          {courseMap.changeSummary?.comments.aos.map((item, i) => (
            <ChangeSummaryComment
              data={item}
              key={i}
              index={i}
              viewOnly={viewOnly}
            />
          ))}
          <div className={c.changedAoS}>
            {changes.aos.subtractions.length ? (
              <ul className={c.subtractedAoS}>
                {changes.aos.subtractions.map((item, i) => {
                  return (
                    <li key={i}>
                      <div>
                        <Icon.Minus />
                        <span>{item.name}</span>
                      </div>
                    </li>
                  )
                })}
              </ul>
            ) : null}
            {changes.aos.additions.length ? (
              <ul className={c.addedAoS}>
                {changes.aos.additions.map((item, i) => {
                  return (
                    <li key={i}>
                      <div>
                        <Icon.Plus />
                        <span>{item.name}</span>
                      </div>
                    </li>
                  )
                })}
              </ul>
            ) : null}
          </div>
        </>
      ) : null}

      {changes.units.additions.length || changes.units.subtractions.length ? (
        <>
          {viewOnly ? (
            <h3>Units</h3>
          ) : (
            <HoverMenu title={<h3>Units</h3>}>
              <button onClick={() => addComment('unit')}>
                <Icon.Comment />
              </button>
            </HoverMenu>
          )}
          {courseMap.changeSummary?.comments.unit.map((item, i) => (
            <ChangeSummaryComment
              data={item}
              key={i}
              index={i}
              viewOnly={viewOnly}
            />
          ))}
          <div className={c.changedUnits}>{renderUnitChanges()}</div>
        </>
      ) : null}
    </div>
  )
}

export default ChangeSummary
