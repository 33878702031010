import { Interface } from 'components/wrappers/interface-data-wrapper/InterfaceDataWrapper'
import React, { useContext } from 'react'
import c from './creditPointsTag.module.scss'

const CreditPointsTag = ({
  blockType,
  origin,
  studentView,
  creditPoints,
  elective,
  selectedElective,
  electives,
}) => {
  const interfaceData = useContext(Interface)

  const show6CPTags = studentView
    ? interfaceData.studentDisplayOptions.show6CPTags
    : interfaceData.displayOptions.show6CPTags

  if (elective && selectedElective && !electives) {
    throw new Error('Missing electives')
  }

  let displayedCreditPoints = 0

  // Display non elective's credit points
  if (!elective) displayedCreditPoints = creditPoints || 0

  // Display selected elective's credit points
  if (elective && selectedElective) {
    const found = electives.find(
      (item) => item.blockType !== 'COMMENT' && item.name === selectedElective
    )

    if (found?.creditType === 'unspecifiedCredit') {
      displayedCreditPoints = 6
    } else {
      displayedCreditPoints = found?.creditPoints || 0
    }
  }

  const showCreditPoints =
    // Type is not comment
    blockType !== 'COMMENT' &&
    // Type is not intermission
    blockType !== 'INTERMISSION' &&
    // Not in handbook
    origin !== 'BROWSER' &&
    // Not an elective or an elective with a selection
    (!elective || (elective && selectedElective)) &&
    // Options for 6CP tags display settings
    (show6CPTags ||
      (!show6CPTags && parseInt(displayedCreditPoints || '') !== 6))

  if (!showCreditPoints) {
    return null
  }

  return (
    <div
      className={`${c.creditPointsTag} ${
        interfaceData.displayOptions.condenseMode ? c.condense : null
      }`}>
      {displayedCreditPoints + 'CP'}
    </div>
  )
}

export default CreditPointsTag
