import React from 'react'
import VBlock from './VBlock'
import c from './view-plan.module.scss'

const VAdvancedStandings = ({ advancedStandings }) => {
  // render

  return (
    <div className={c.advancedStandings}>
      <h3>Credit for prior learning</h3>
      <div className={c.blocks}>
        {advancedStandings.map((block, i) => (
          <VBlock block={block} key={i} />
        ))}
      </div>
    </div>
  )
}

export default VAdvancedStandings
