import React from 'react'
import c from './progressBar.module.scss'

const ProgressBar = ({ adv, comp, enr, total }) => {
  const blob = (points, type) => {
    switch (type) {
      case 'adv':
        return (
          <div
            className={c.blob}
            style={{
              left: 0,
              width: `calc(${(points / total) * 100}%)`,
              background: `linear-gradient(90deg, #604088, #704F98)`,
              zIndex: 4,
            }}></div>
        )
      case 'comp':
        return (
          <div
            className={c.blob}
            style={{
              left: `calc(${(adv / total) * 100}% ${adv > 0 ? '- 2%' : ''})`,
              width: `calc(${(points / total) * 100}% ${
                adv > 0 ? '+ 2%' : ''
              })`,
              background: 'linear-gradient(90deg, #204F88, #35639B)',
              zIndex: 3,
            }}></div>
        )
      case 'enr':
        return (
          <div
            className={c.blob}
            style={{
              left: `calc(${(adv / total + comp / total) * 100}% ${
                adv > 0 || comp > 0 ? '- 2%' : ''
              })`,
              width: `calc(${(points / total) * 100}% ${
                adv > 0 || comp > 0 ? '+ 2%' : ''
              })`,
              background:
                'repeating-linear-gradient(-45deg, #38853C, #38853C 5px, #68A26B 5px, #68A26B 10px)',
              zIndex: 2,
            }}></div>
        )
      default:
        return
    }
  }

  const labels = () => {
    let chunks = []
    for (let i = 0; i <= total / 6; i++) {
      chunks.push(i * 6)
    }

    return chunks.map((c, i) => (
      <span
        key={i}
        style={
          i === chunks.length - 1 && i % 24
            ? {
                left: `100%`,
              }
            : { left: `calc(${(100 / (total / 6)) * i}%)` }
        }>
        {c % 24 === 0 ? c : i === chunks.length - 1 ? c : ''}
      </span>
    ))
  }

  return (
    <div>
      <div className={c.progress}>
        <div className={c.bar}>
          {adv > 0 && blob(adv, 'adv')}
          {comp > 0 && blob(comp, 'comp')}
          {enr > 0 && blob(enr, 'enr')}
        </div>
        <div className={c.labels}>{labels()}</div>
      </div>
      <div className={c.key}>
        {adv > 0 && (
          <div className={c.keyItem}>
            <div style={{ background: '#604088' }} className={c.keyBlob}>
              {' '}
            </div>
            <p>Credit (Prior study): {adv}</p>
          </div>
        )}
        {comp > 0 && (
          <div className={c.keyItem}>
            <div style={{ background: '#35639B' }} className={c.keyBlob}>
              {' '}
            </div>
            <p>Completed: {comp}</p>
          </div>
        )}
        {enr > 0 && (
          <div className={c.keyItem}>
            <div
              style={{
                background:
                  'repeating-linear-gradient(-45deg, #38853C, #38853C 10px, #68A26B 10px, #68A26B 20px)',
              }}
              className={c.keyBlob}>
              {' '}
            </div>
            <p>Enrolled: {enr}</p>
          </div>
        )}
        <div className={c.keyItem}>
          <div style={{ background: '#fafafa' }} className={c.keyBlob}>
            {' '}
          </div>
          <p>Remaining: {total - (adv + comp + enr)}</p>
        </div>
      </div>
    </div>
  )
}

export default ProgressBar
