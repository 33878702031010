import React from 'react'
import c from './text-area.module.scss'

const TextArea = React.forwardRef(
  (
    { onChange, value, onBlur, onKeyDown, className, autoFocus, onFocus },
    ref
  ) => {
    // render

    return (
      <div className={c.TextArea}>
        <textarea
          {...{
            ref,
            className,
            onKeyDown,
            onChange,
            onBlur,
            value,
            autoFocus,
            onFocus,
          }}
          rows={1}
          onInput={(e) => {
            e.target.parentNode.dataset.replicatedValue = e.target.value
          }}
          onFocus={(e) => {
            e.target.parentNode.dataset.replicatedValue = e.target.value
          }}></textarea>
      </div>
    )
  }
)

export default TextArea
