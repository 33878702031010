import React, { useContext } from 'react'
import c from './error.module.scss'
import Illustration from 'components/utilities/icons/Illustration'
import { RouterState } from 'utils/Router'
import { ErrorData } from 'components/wrappers/error-wrapper/ErrorWrapper'

const Error = ({ message }) => {
  const { redirect } = useContext(RouterState)
  const { setError } = useContext(ErrorData)

  return (
    <div className={c.error}>
      <div className={c.content}>
        <Illustration.Error />
        <h2>{message || 'Oops, something went wrong!'}</h2>
      </div>
      <div className={c.actions}>
        <button
          className={c.report}
          onClick={() => {
            setError({ type: null })
            redirect('/feedback')
          }}>
          Report a bug
        </button>
        <button
          className={c.back}
          onClick={() => {
            setError({ type: null })
            window.history.back()
          }}>
          Previous page
        </button>
      </div>
    </div>
  )
}

export default Error
