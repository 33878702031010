import Icon from 'components/utilities/icons/Icon'
import { TutorialContext } from 'components/utilities/tutorial/TutorialProvider'
import { CurrentStudent } from 'components/wrappers/student-data-wrapper/StudentDataWrapper'
import React, { useContext, useLayoutEffect, useRef } from 'react'
import { Link } from 'utils/Router'
import Collapsible from './collapsible/Collapsible'
import c from './sidebar.module.scss'

const Sidebar = ({ switchStudent }) => {
  const { currentStudent, bookmarks } = useContext(CurrentStudent)

  // tutorial
  const { updateRef } = useContext(TutorialContext)
  const sidebarContainerRef = useRef()
  const bookmarkContainerRef = useRef()

  useLayoutEffect(() => {
    updateRef('Navigation bar', sidebarContainerRef.current)
  }, [updateRef])

  useLayoutEffect(() => {
    updateRef('Related pages', bookmarkContainerRef.current)
  }, [updateRef])

  const renderBookmarks = (type) => {
    return bookmarks
      .filter((b) => b.type === type)
      .map((b, i) => {
        return (
          <Link
            key={`bookmark${i}`}
            to={`/browse/${b.type}/${b.code}?year=${b.year}`}
            data={b}>
            <span className={c.item}>
              {b.code} {'\u2013'} {b.title}
            </span>
          </Link>
        )
      })
  }

  // render

  return (
    <div className={c.sidebar} id='sidebar' ref={sidebarContainerRef}>
      {/* meta */}
      <section className={c.meta}>
        <label>Advice for</label>
        <h3>
          {(currentStudent.personName?.givenNames || [])
            .join(' ')
            .toLowerCase()}{' '}
          {currentStudent.personName?.familyName.toLowerCase()} {'\u2013'}{' '}
          {currentStudent.identifiers?.callistaPersonID}
        </h3>
        <button onClick={switchStudent}>Switch student</button>
      </section>

      {/* plans */}
      <section className={c.plans}>
        <label>Student plans</label>
        <Link to='plans'>
          <Icon.Home />
          <span className={c.item}>
            <span className={`${c.item} ${c.title}`}>
              {currentStudent.personName?.givenNames
                .join(' ')
                .toLowerCase()
                .trim() || 'Student'}
            </span>
            's plans
          </span>
        </Link>
      </section>

      {/* related pages */}
      {bookmarks && (
        <section className={c.relatedPages} ref={bookmarkContainerRef}>
          <label>Bookmarks</label>
          {bookmarks.some((b) => b.type === 'course') && (
            <Collapsible header={'Courses'} expanded={false}>
              {renderBookmarks('course')}
            </Collapsible>
          )}
          {bookmarks.some((b) => b.type === 'aos') && (
            <Collapsible header={'Areas of study'} expanded={false}>
              {renderBookmarks('aos')}
            </Collapsible>
          )}

          {bookmarks.some((b) => b.type === 'unit') && (
            <Collapsible header={'Units'} expanded={false}>
              {renderBookmarks('unit')}
            </Collapsible>
          )}
        </section>
      )}
    </div>
  )
}

export default Sidebar
