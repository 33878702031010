import React from 'react'
import PMapBlocks from './PMapBlocks'
import c from './progression-map.module.scss'

const PMapSection = ({ data, year, remaining }) => {
  // render

  return (
    <div className={c.PMapSection}>
      {data.map((item, i) => (
        <div key={i} className={c.period}>
          <label>{`Year ${year}: ${
            item.period?.length > 4
              ? item.period
              : 'Semester ' + (item.period || i)
          }`}</label>

          <PMapBlocks data={item.units} remaining={remaining} />
        </div>
      ))}
    </div>
  )
}

export default PMapSection
